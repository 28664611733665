export function unreachable(message?: string): never {
    throw new Error(message ?? "Unreachable code path executed");
}

export function unreachableSwitchCase(value: unknown): never {
    throw new Error(`Unhandled switch case, value: ${JSON.stringify(value)}`);
}

export function exhaustiveSwitchCheck(value: never): never {
    unreachableSwitchCase(value);
}
