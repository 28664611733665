/**
 * Maps the key-value pairs of an object to a new object using a mapping function.
 *
 * @param obj - The object to be mapped.
 * @param fn - The mapping function that takes a key and value of the object and returns a tuple of the new key and value.
 * @returns The new object with the mapped key-value pairs.
 */
export function mapObj<T extends object, RK extends string | number | symbol, RV>(
    obj: T,
    fn: (k: keyof T, v: T[keyof T]) => [RK, RV],
) {
    const result = {} as { [k in RK]: RV };
    for (const k in obj) {
        const [rk, rv] = fn(k, obj[k]);
        result[rk] = rv;
    }
    return result;
}
