import C from "./button/WButton.module.css";

import { discardPromiseFn } from "@warrenio/utils/promise/discardPromise";
import sleep from "@warrenio/utils/promise/sleep";
import { useRef, useState, type ReactNode } from "react";
import type { BaseProps } from "../utils/baseProps.ts";
import { cn } from "../utils/classNames.ts";
import { copyElementToClipboard, copyTextToClipboard } from "../utils/clipboard.ts";
import { WTooltip } from "./WTooltip.tsx";
import { WButton } from "./button/WButton.tsx";
import { MaskIcon } from "./icon/MaskIcon.tsx";

interface ClipBoardTooltipBaseProps {
    children?: ReactNode | string;
    /** Copy rich HTML snippet of the children to the clipboard */
    isHtml?: boolean;
    text?: string;
    props?: BaseProps;
}

export interface ClipBoardTooltipPlainProps extends ClipBoardTooltipBaseProps {
    isHtml?: false | undefined;
    text: string;
}

export interface ClipBoardTooltipSimpleProps extends ClipBoardTooltipBaseProps {
    /** If there is a single child which is a string, there is no need to specify other props. */
    children: string | number;
}

export interface ClipboardTooltipHtmlProps extends ClipBoardTooltipBaseProps {
    isHtml: true;
    /** Optional property to specify fallback text for clipboard. Not required if `isHtml={true}`. */
    text?: string;
}

export type ClipboardTooltipProps =
    | ClipBoardTooltipPlainProps
    | ClipboardTooltipHtmlProps
    | ClipBoardTooltipSimpleProps;

export function ClipBoardTooltip({
    children,
    text = typeof children === "string" || typeof children === "number" ? String(children) : undefined,
    isHtml = false,
    props,
}: ClipboardTooltipProps) {
    const childrenRef = useRef<HTMLElement>(null);

    const [isCopied, setIsCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isForceOpen, setIsForceOpen] = useState(false);

    const actualIsOpen = isForceOpen || isOpen;

    const handleCopyClick = async () => {
        if (isHtml) {
            await copyElementToClipboard(childrenRef.current!, text);
        } else {
            await copyTextToClipboard(text!);
        }
        setIsCopied(true);
        setIsForceOpen(true);

        await sleep(1000);
        setIsCopied(false);

        await sleep(1000);
        setIsForceOpen(false);
    };

    return (
        <WTooltip text={isCopied ? "Copied!" : "Copy to clipboard"} isOpen={actualIsOpen} onOpenChange={setIsOpen}>
            {isHtml ? (
                // TODO: This is not accessible with keyboard navigation
                <span
                    className={cn(C.Button, C.xs, C.ghost, C.primary, "cursor-pointer")}
                    onClick={discardPromiseFn(handleCopyClick)}
                    {...props}
                >
                    <span className="color-text" ref={childrenRef}>
                        {children}
                    </span>{" "}
                    <MaskIcon className="ml-1 jp-duplicate-icon size-0.875rem min-w-0.875rem text-primary" />
                </span>
            ) : (
                <WButton
                    variant="ghost"
                    color="primary"
                    size="xs"
                    action={discardPromiseFn(handleCopyClick)}
                    {...props}
                >
                    <span className="color-text">{children}</span>
                    <MaskIcon
                        className={cn(
                            !!children && "ml-1",
                            "jp-duplicate-icon size-0.875rem min-w-0.875rem text-primary",
                        )}
                    />
                </WButton>
            )}
        </WTooltip>
    );
}
