import { atom, type WritableAtom } from "jotai/vanilla";

const emptyGetter = () => undefined;

export type ActionAtom<Args extends unknown[] = never[], Result = void> = WritableAtom<void, Args, Result>;

/**
 * An atom that just executes an action with optional arguments (it does not have a value).
 *
 * Useful for making simple functions that can access the store, without coupling them to React hooks via `useAtom`.
 *
 * @__NO_SIDE_EFFECTS__
 */
export function atomAction<Args extends unknown[], Result>(
    write: ActionAtom<Args, Result>["write"],
): ActionAtom<Args, Result> {
    return atom(emptyGetter, write);
}
