import C from "./Editable.module.css";

import type { ReactNode } from "react";
import type { ActionEventFn } from "../Action.tsx";
import { WButton } from "../button/WButton.tsx";

export function EditableButton({ action, children }: { action: ActionEventFn | undefined; children: ReactNode }) {
    return (
        <div className={C.Editable}>
            <WButton action={action} variant="editable" icon="jp-icon-edit" iconSide="right">
                {children}
            </WButton>
        </div>
    );
}
