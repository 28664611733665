/** Common properties for all design system components. */
export interface BaseProps {
    className?: string | undefined;
    // TODO: do we need to add more properties here?
    "data-testid"?: string | undefined;
}

export type WithBaseProps<T> = T & BaseProps;

/**
 * Merge multiple {@link BaseProps} into a single {@link BaseProps}. Useful for making extensible base components.
 *
 * @see https://react-spectrum.adobe.com/react-aria/mergeProps.html for inspiration.
 */
export function mergeProps<T extends BaseProps>(...props: T[]) {
    return mcn("", ...props);
}

/**
 * "Merge class names".
 * Variant of {@link mergeProps} which takes an explicit base {@link className}.
 * @example <div {...mcn("base-class", props)} />
 */
export function mcn<T extends BaseProps>(className: string, ...props: T[]) {
    const otherProps = { className: "" } as T & { className: string };
    for (const prop of props) {
        const { className: propClassName, ...restProps } = prop;
        for (const key in restProps) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const propValue: unknown = (restProps as any)[key];
            if (typeof propValue !== "undefined") {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                (otherProps as any)[key] = propValue;
            }
        }

        if (propClassName) {
            className += ` ${propClassName}`;
        }
    }
    otherProps.className = className;
    return otherProps;
}
