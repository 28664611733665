import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import { atom } from "jotai/vanilla";
import type { SiteConfig } from "./config.types.ts";
import { mockLocationConfig, singleLocationList } from "./mock-api/data/locationsExample.ts";
import { atomWithLocalStorage } from "./utils/jotai/atomStorage.ts";

const baseConfig = {
    loginTitle: '"How long is forever? Sometimes, just one second."',
    loginSubtitle: "- Lewis Carroll, Alice in Wonderland",

    supportEmail: "support@warren.io",
    billingEmail: "billing@warren.io",
    apiDocUrl: "https://test-api.pilw.io/",
    supportUrl: "https://test-api.pilw.io/",

    s3ForcePathStyle: true,
    chatEnabled: false,
    chatConfig: {},

    auth0: {
        domain: "test-login.pilw.io",
        clientID: "C5FGNyCE_TsXvLn2oxHjkNoB4z5Qe6WY",
    },
} satisfies Partial<SiteConfig>;

export const mockConfig: SiteConfig = {
    ...baseConfig,

    siteName: "Warren.io Test Conf",

    siteTheme: "warren",

    siteCountry: "EE",
    siteLocale: "en-GB",
    siteLanguage: "id-ID",

    siteCurrency: "EUR",

    locations: mockLocationConfig,

    vmsEnabled: true,
    vmAppsEnabled: true,
    ipsEnabled: true,
    privateNetworksEnabled: true,
    loadBalancerEnabled: true,
    storageEnabled: true,
    servicesEnabled: true,
    kubernetesEnabled: true,
    bareMetalEnabled: true,
    mfaAvailable: true,

    recurringPaymentsEnabled: true, // default true
    firstAccountCredit: 22,
    referralCodeEnabled: true, // default false
    referralCreditAmounts: {
        receiver_credit: 5.44,
        promoter_credit: 4.93,
        referral_credit_receiver_precondition_topup_sum: 11.33,
    },
    limitedBillingAccountConfig: { vcpu: 1, ram_mb: 2048 },
    minimumTopupAmount: 13,
    allowDebt: true, // default true

    predefinedPackages: [
        {
            cpu: 1,
            ram: 1,
            ssd: 20,
            default: [],
            os: ["win"],
            disabled: ["win"],
            notice: "Currently not available",
        },
        {
            cpu: 2,
            ram: 1,
            ssd: 20,
            default: ["other"],
            os: ["other"],
        },
        {
            cpu: 2,
            ram: 2,
            ssd: 50,
            default: [],
            os: ["other", "win"],
            notice: "Special offer",
        },
        {
            cpu: 2,
            ram: 4,
            ssd: 60,
            default: ["win"],
            os: ["other", "win"],
        },
        {
            cpu: 4,
            ram: 4,
            ssd: 80,
            default: [],
            os: ["win"],
        },
    ],
    paymentProcessors: [
        // Prefer Omise in mock mode
        {
            type: "creditcard",
            name: "Credit card (Omise)",
            keyword: "omise_creditcard",
            verification_amount: 2000,
            public_key: "pkey_test_5u2cv09rmzfksvc5oms",
            use_3ds_verification: 0,
        },
        {
            type: "creditcard",
            name: "Credit card (Stripe)",
            keyword: "stripe_creditcard",
            public_key: "pk_test_0vqb6B7pEQH096m2iiZgR5WR00QjI3ITWA",
            verification_amount: 100,
            show_postalcode_input: 1,
            service_fee: {
                flat_rate: 2500,
                percentage: 3.5,
            },
        },

        {
            type: "link",
            name: "SSLCOMMERZ",
            keyword: "sslcommerz",
            store_id: "tenby660726d4e2274",
            methods: [
                { key: "sslcommerz", name: "SSLCOMMERZ" },

                { key: "brac_visa", name: "BRAC VISA (SSLCMRZ)" },
                { key: "dbbl_visa", name: "Dutch Bangla VISA (SSLCMRZ)" },
                { key: "city_visa", name: "City Bank Visa (SSLCMRZ)" },
                { key: "ebl_visa", name: "EBL Visa (SSLCMRZ)" },
                { key: "sbl_visa", name: "Southeast Bank Visa (SSLCMRZ)" },
                { key: "brac_master", name: "BRAC MASTER (SSLCMRZ)" },
                { key: "dbbl_master", name: "MASTER Dutch-Bangla (SSLCMRZ)" },
                { key: "city_master", name: "City Master Card (SSLCMRZ)" },
                { key: "ebl_master", name: "EBL Master Card (SSLCMRZ)" },
                { key: "sbl_master", name: "Southeast Bank Master Card (SSLCMRZ)" },
                { key: "city_amex", name: "City Bank AMEX (SSLCMRZ)" },
                { key: "qcash", name: "QCash (SSLCMRZ)" },
                { key: "dbbl_nexus", name: "DBBL Nexus (SSLCMRZ)" },
                { key: "bankasia", name: "Bank Asia IB (SSLCMRZ)" },
                { key: "abbank", name: "AB Bank IB (SSLCMRZ)" },
                { key: "ibbl", name: "IBBL IB and Mobile Banking (SSLCMRZ)" },
                { key: "mtbl", name: "Mutual Trust Bank IB (SSLCMRZ)" },
                { key: "bkash", name: "Bkash Mobile Banking (SSLCMRZ)" },
                { key: "dbblmobilebanking", name: "DBBL Mobile Banking (SSLCMRZ)" },
                { key: "city", name: "City Touch IB (SSLCMRZ)" },
                { key: "upay", name: "Upay (SSLCMRZ)" },
                { key: "tapnpay", name: "Tap N Pay Gateway (SSLCMRZ)" },

                { key: "internetbank", name: "Internet banking (SSLCMRZ)" },
                { key: "mobilebank", name: "Mobile banking (SSLCMRZ)" },
                { key: "visacard", name: "Visa card (SSLCMRZ)" },
                { key: "mastercard", name: "Master card (SSLCMRZ)" },
                { key: "amexcard", name: "Amex card (SSLCMRZ)" },
                { key: "othercard", name: "Other cards (SSLCMRZ)" },
            ],
        },
        {
            type: "link",
            name: "Duitku",
            keyword: "duitku",
            public_key: "D5894",
            methods: [
                {
                    key: "VC",
                    name: "Credit Card (Visa / Master)",
                },
                {
                    key: "BK",
                    name: "BCA KlikPay",
                },
                {
                    key: "M1",
                    name: "Mandiri Virtual Account",
                },
                {
                    key: "BT",
                    name: "Permata Bank Virtual Account",
                },
                {
                    key: "B1",
                    name: "CIMB Niaga Virtual Account",
                },
                {
                    key: "A1",
                    name: "ATM Bersama",
                },
                {
                    key: "I1",
                    name: "BNI Virtual Account",
                },
                {
                    key: "VA",
                    name: "Maybank Virtual Account",
                },
                {
                    key: "FT",
                    name: "Ritel",
                },
                {
                    key: "SP",
                    name: "Shopee Pay",
                },
                {
                    key: "OV",
                    name: "OVO",
                },
            ],
        },
        {
            type: "link",
            name: "Wallets",
            keyword: "stripe_wallet",
            public_key:
                "pk_test_51KqvsdI7ApJ8GXEsuvT46JSJzsIFFVtTac2op0cJThOEKqxjz26rhcqi34ROYfS4UxQDVWn3ztsyxQIFd3EpaUVS00WHzFWEZN",
            methods: [
                {
                    key: "alipay",
                    name: "Alipay",
                },
                {
                    key: "grabpay",
                    name: "GrabPay",
                },
            ],
            charge_currency_conversion: {
                to_currency: "SGD",
                exchange_rate: 8.583691e-5,
            },
            service_fee: {
                flat_rate: 4240,
                percentage: 3.4,
            },
        },
        {
            type: "invoice",
            name: "Invoice payment",
            keyword: "invoice",
        },
        {
            type: "link",
            name: "Omise",
            keyword: "omise",
            public_key: "pkey_test_5u2cv09rmzfksvc5oms",
            methods: [
                {
                    key: "alipay",
                    name: "Alipay (by Omise)",
                },
                {
                    key: "truemoney",
                    name: "TrueMoney Wallet",
                },
                {
                    key: "rabbit_linepay",
                    name: "Rabbit LINE Pay",
                },
                {
                    key: "internet_banking_bay",
                    name: "Bank of Ayudhya (Krungsri)",
                },
                {
                    key: "internet_banking_bbl",
                    name: "Bangkok Bankv",
                },
                {
                    key: "promptpay",
                    name: "PromptPay",
                },
            ],
            charge_currency_conversion: {
                exchange_rate: 35,
                to_currency: "THB",
            },
        },
    ],

    billingAccountFields: [
        { field: "email", required: true },
        { field: "address_line1", required: false },
        { field: "city", required: false },
        { field: "company_name", required: false },
        { field: "company_reg_code", required: false },
        { field: "company_vat_number", required: false, visible: false },
        { field: "country", required: false },
        { field: "county", required: false },
        { field: "customer_name", required: false },
        { field: "customer_phone", required: true },
        { field: "customer_id_number", required: false, visible: false },
        { field: "post_index", required: false },
        { field: "document", required: false },
    ],

    chatConfig: {
        type: "intercom",
        intercom_app_id: "xafgkrwj",

        //type : "qiscus",
        //qiscus_client_key : "gis-xuqqz9ghyfnkrtn6j",
        //qiscus_channel_id : "126886"

        //type: "botika",
        //botika_client_key: "ZmFGjDUtppygdBAM8"

        //type: "oncehub",
        //oncehub_website_id: "WEB-a358762760",
        //oncehub_bot_id: "12345"
    },
};

export const mockMetalConfig: SiteConfig = {
    ...baseConfig,

    siteName: "Bare Metal",
    siteTheme: "metal",
    siteCountry: "EE",
    siteLocale: "en-US",
    siteLanguage: "en-US",
    siteCurrency: "EUR",

    // TODO: Put right locations here?
    locations: singleLocationList,

    vmsEnabled: false,
    vmAppsEnabled: false,
    ipsEnabled: false,
    privateNetworksEnabled: false,
    loadBalancerEnabled: false,
    storageEnabled: false,
    servicesEnabled: false,
    kubernetesEnabled: false,
    bareMetalEnabled: true,
    mfaAvailable: false,

    billingAccountFields: [{ field: "email", required: true }],

    minimumTopupAmount: 500,
    // XXX: Is this correct?
    allowDebt: false,

    paymentProcessors: [
        {
            type: "creditcard",
            name: "Credit card (Stripe)",
            keyword: "stripe_creditcard",
            public_key: "pk_test_0vqb6B7pEQH096m2iiZgR5WR00QjI3ITWA",
            verification_amount: 1,
            service_fee: {
                flat_rate: 0.35,
                percentage: 3.5,
            },
        },
    ],

    recurringPaymentsEnabled: true,
    firstAccountCredit: 0,
    referralCodeEnabled: false,
    referralCreditAmounts: {
        receiver_credit: 0,
        promoter_credit: 0,
        referral_credit_receiver_precondition_topup_sum: undefined,
    },
    limitedBillingAccountConfig: {
        vcpu: 0,
        ram_mb: 0,
    },
    predefinedPackages: [],
};

export const allMockConfigs = {
    default: mockConfig,
    metal: mockMetalConfig,
};

export type MockProvider = keyof typeof allMockConfigs;

export const mockProviderAtom = atomWithLocalStorage<MockProvider>("mockProvider", "default");

export const mockConfigAtom = atom((get) => mustGetProperty(allMockConfigs, get(mockProviderAtom)));
