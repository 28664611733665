/** @returns if the array is sorted in ascending order according to the {@link key} function */
export function isAscending<T>(arr: T[], key: (item: T) => number | string) {
    if (arr.length === 0) {
        return true;
    }

    let lastKey = key(arr[0]);
    for (let i = 1; i < arr.length; i++) {
        const currentKey = key(arr[i]);
        if (currentKey < lastKey) {
            return false;
        }
        lastKey = currentKey;
    }

    return true;
}
