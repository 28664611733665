export function notNull<T>(value: T | null | undefined, name = "Value"): T {
    if (value === null || value === undefined) {
        throw new Error(`${name} is null or undefined`);
    }
    return value;
}

export function assertNotNull<T>(value: T | null | undefined, name = "Value"): asserts value is T {
    if (value === null || value === undefined) {
        throw new Error(`${name} is null or undefined`);
    }
}
