import type { AccessDelegation, ApiToken } from "@warrenio/api-spec/spec.oats.gen";
import { link } from "../../components/Action.tsx";

export function delegationLink({ id }: AccessDelegation) {
    return link({ to: "/access/delegation/$id", params: { id: String(id) } });
}

export function tokenLink({ id }: ApiToken) {
    return link({ to: "/access/token/$id", params: { id: String(id) } });
}
