import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";
import L from "./Login.module.css";

import { useState } from "react";
import { Input, type InputProps } from "react-aria-components";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";
import { WButton } from "../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../components/forms/FieldButtonGroup.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { useAriaField } from "../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../components/forms/requiredMessage.ts";
import { cn } from "../../utils/classNames.ts";

export function InputRow<T extends FieldValues>({
    name,
    label,
    isRequired = true,
    ...inputProps
}: { name: Path<T>; label: string; isRequired?: boolean } & InputProps) {
    const { type } = inputProps;

    const form = useFormContext<T>();
    const { props, ref } = useAriaField(name, form, {
        required: isRequired ? requiredMessage : false,
    });

    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <WTextField className={FF.Block} wide aria-label={label} type="text" {...props}>
            {type === "password" ? (
                <FieldButtonGroup hasAbsoluteButton>
                    <Input
                        ref={ref}
                        className={cn(FF.FormFieldInput, TF.Input, L.Input)}
                        id={name}
                        placeholder={label}
                        {...inputProps}
                        // Override passed-in type
                        type={passwordVisible ? "text" : "password"}
                    />

                    <WButton
                        size="xs"
                        variant="ghost"
                        action={() => setPasswordVisible((v) => !v)}
                        icon={cn("size-0.875rem", passwordVisible ? "jp-nopreview-icon" : "jp-preview-icon")}
                    />
                </FieldButtonGroup>
            ) : (
                <Input
                    ref={ref}
                    className={cn(FF.FormFieldInput, TF.Input, L.Input)}
                    id={name}
                    placeholder={label}
                    {...inputProps}
                />
            )}
        </WTextField>
    );
}
