import C from "./WTooltip.module.css";

import { Children, useRef, type ReactNode } from "react";
import { useFocusable, type FocusableOptions, type TooltipTriggerProps } from "react-aria";
import { OverlayArrow, Tooltip, TooltipTrigger, type TooltipProps } from "react-aria-components";
import type { BaseProps } from "../utils/baseProps";
import { cn } from "../utils/classNames.ts";
import { MaskIcon } from "./icon/MaskIcon.tsx";

export interface WTooltipProps
    extends Pick<
            TooltipTriggerProps,
            "delay" | "closeDelay" | "isOpen" | "onOpenChange" | "defaultOpen" | "isDisabled"
        >,
        Pick<TooltipProps, "placement"> {
    children?: ReactNode;
    text?: ReactNode;
    /** Whether the tooltip text is selectable. (which also means the tooltip will not be closed when it is hovered) */
    isSelectable?: boolean;
    wide?: boolean;
}

/** Hack to make a <div> focusable since {@link TooltipTrigger} requires at least once focusable child. */
function FocusableDiv({
    children,
    className,
    excludeFromTabOrder = true,
    ...props
}: FocusableOptions & { children?: ReactNode } & BaseProps) {
    const ref = useRef<HTMLDivElement>(null);
    const { focusableProps } = useFocusable({ ...props, excludeFromTabOrder }, ref);
    return (
        <div className={className} ref={ref} {...focusableProps}>
            {children}
        </div>
    );
}

export function WTooltip({
    children,
    text,
    isSelectable = false,

    placement,
    wide,
    delay = 300,
    // TODO: Setting a `closeDelay` seems to sometimes leave multiple tooltips open (see eg. in the "VPC > Resources" table when moving over the same row)
    closeDelay = 100,
    ...props
}: WTooltipProps) {
    // XXX: focusable props are duplicated if there is more than one focusable child (eg. a button)
    return (
        <TooltipTrigger delay={delay} closeDelay={closeDelay} {...props}>
            <FocusableDiv className={C.TooltipHolder}>{children}</FocusableDiv>
            <Tooltip
                className={cn(C.Tooltip, !isSelectable && "pointer-events-none", wide && C.wide)}
                placement={placement}
            >
                <TooltipArrow />
                {text}
            </Tooltip>
        </TooltipTrigger>
    );
}

function TooltipArrow() {
    return (
        <OverlayArrow className={C.OverlayArrow}>
            <svg width={8} height={8} viewBox="0 0 8 8" aria-hidden="true">
                <path d="M0 0 L4 4 L8 0" />
            </svg>
        </OverlayArrow>
    );
}

export function InfoIcon({ className }: { className?: string }) {
    return <MaskIcon className={cn(className, "jp-information-icon size-0.875rem text-primary")} />;
}

export function InfoTooltip({ className, children, ...props }: WTooltipProps & { className?: string }) {
    return (
        <WTooltip {...props}>
            {children}
            <InfoIcon className={cn(className, Children.count(children) > 0 && "ml-1")} />
        </WTooltip>
    );
}
