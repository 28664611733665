/** Asynchronously load a script */
export function loadScript(url: string, setAttributes?: (el: HTMLScriptElement) => void): Promise<Event> {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onerror = reject;
        script.onload = resolve;
        setAttributes?.(script);

        console.debug("Loading script: %s", url);
        document.head.prepend(script);
    });
}
