/** Sets a timeout and returns a function to clean it up, suitable for eg. using in React hooks. */
export function cleanTimeout(fn: () => void, ms = 0): () => void {
    let id: ReturnType<typeof setTimeout> | null = setTimeout(() => {
        fn();
        id = null;
    }, ms);

    return () => {
        if (id) {
            clearTimeout(id);
        }
    };
}
