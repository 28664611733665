import { isMobileDevice } from "../modules/main/mobile/isMobile.ts";

export function initBodyClasses() {
    const html = document.documentElement;

    html.classList.add(getBodyClasses());
}

export function getBodyClasses(): string {
    return isMobileDevice ? "mobile" : "desktop";
}
