const MARKER = Symbol.for("TODO/marker");

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export function todoFn<T extends (...args: any) => any>(fn: T): T {
    (fn as any)[MARKER] = true;
    return fn;
}

export function isTodoFn(fn: unknown): boolean {
    return typeof fn === "function" && (fn as any)[MARKER] === true;
}
