import { asArray } from "./asArray.ts";

export function filterNulls<T>(obj: Iterable<T | null | undefined>): T[] {
    return asArray(obj).filter((x): x is T => x !== null && x !== undefined);
}

export function filterObjNulls<T extends object>(obj: T) {
    const result = {} as { [K in keyof T]: Exclude<T[K], null | undefined> };
    for (const k in obj) {
        const v = obj[k];
        if (v != null) {
            result[k] = v as Exclude<T[typeof k], null | undefined>;
        }
    }
    return result;
}
