/** Discard a promise (useful for silencing lint errors) */
export function discardPromise(promise: Promise<any> | void): void {
    if (!promise) return;
    promise.catch((e) => {
        console.error("Error in discarded promise", e);
    });
}

/** Ignore the promise return value of a function (just log any errors) */
export function discardPromiseFn<TArgs extends unknown[]>(
    fn: (...args: TArgs) => Promise<any>,
): (...args: TArgs) => void {
    return (...args: TArgs) => {
        discardPromise(fn(...args));
    };
}

/** Execute a promise-returning function and discard the Promise result */
export function discardAsync(fn: () => Promise<any>): void {
    discardPromise(fn());
}
