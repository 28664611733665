import { keys } from "remeda";
import * as defaultTheme from "../../styles/defaultTheme.ts";
import { themeToCss } from "./cssBuilder.ts";
import type {
    AllThemeVars,
    AllThemeVarsGetters,
    CssPropertyValue,
    Theme,
    ThemeBuilder,
    ThemeContext,
    ThemeModule,
    ThemeProps,
    ThemeVariant,
} from "./types.ts";

let currentContext: ThemeContext | undefined;

function getThemeContext() {
    if (!currentContext) {
        throw new Error("No active theme context. Did you forget to wrap the variable's value in a function?");
    }
    return currentContext;
}

/** Get a theme variable's current value (available only when building a theme) */
export function getVarFromBuilderContext(key: string) {
    return getThemeContext().get(key);
}

function resolveThemeToVars(builder: ThemeBuilder, variant: ThemeVariant): AllThemeVars {
    const vars: AllThemeVarsGetters = { ...defaultTheme.makeTheme(variant), ...builder(variant) };

    const themeContext: ThemeContext = {
        ...variant,
        get,
    };

    const currentlyResolving = new Set<string>();

    const values: Partial<Record<keyof typeof vars, CssPropertyValue>> = {};

    function get(_name: string): CssPropertyValue | undefined {
        const name = _name as keyof typeof values;

        if (currentlyResolving.has(name)) {
            throw new Error(`Circular reference detected to ${name} in path ${[...currentlyResolving].join(" -> ")}`);
        }

        currentlyResolving.add(name);
        try {
            // Check if value has already been calculated
            if (name in values) {
                return values[name];
            }

            if (!(name in vars)) {
                return undefined;
            }

            const value = vars[name];
            const resolvedValue = typeof value === "function" ? value(themeContext) : value;
            values[name] = resolvedValue;
            return resolvedValue;
        } finally {
            currentlyResolving.delete(name);
        }
    }

    try {
        currentContext = themeContext;

        // Resolve all variables
        for (const key of keys(vars)) {
            get(key);
        }

        return values as AllThemeVars;
    } finally {
        currentContext = undefined;
    }
}

export function buildThemeVars(themeBuilder: ThemeBuilder): Theme {
    return {
        vars: resolveThemeToVars(themeBuilder, { isDark: false }),
        darkVars: resolveThemeToVars(themeBuilder, { isDark: true }),
    };
}

export function buildThemeProps(theme: ThemeModule): ThemeProps {
    return { ...defaultTheme.props, ...theme.props };
}

export function buildTheme(theme: ThemeModule) {
    return {
        css: themeToCss(buildThemeVars(theme.makeTheme)),
        props: buildThemeProps(theme),
    };
}
