import { Link } from "react-aria-components";
import { useThemeProps } from "../modules/theme/useTheme.ts";

export function IsoCerts() {
    const { isoCertificates } = useThemeProps();

    return isoCertificates?.map((item, index) => (
        <Link href={item.url} target="_blank" key={index}>
            <img src={item.image} alt="Certificate" />
        </Link>
    ));
}
