import L from "../Login.module.css";

import { useAtomValue, useStore } from "jotai/react";
import { useEffect, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import invariant from "tiny-invariant";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { WHookForm } from "../../../components/forms/WHookForm.tsx";
import { Loading } from "../../../components/loading/Loading.tsx";
import { isBuiltRelease } from "../../../utils/environment.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { useSimpleMutation } from "../../../utils/react/useSimpleMutation.tsx";
import { getResponseData } from "../../api/apiClient.ts";
import { apikeyAtom } from "../../api/apikey.store.ts";
import { unauthApiClientAtom } from "../../api/createApiClient.ts";
import { errorToString } from "../../main/errorToString.tsx";
import { getCurrentPath, getRelativeUrl } from "../../main/urls.ts";
import { InputRow } from "../InputRow.tsx";
import { LoginBox, LoginLayout } from "../LoginLayout.tsx";
import type { SignupResult } from "../authProvider.ts";
import {
    isAuth0Error,
    type Auth0CallbackError,
    type Auth0CallbackResult,
    type Auth0CallbackSuccess,
} from "./auth0Callback.ts";
import { auth0ProviderAtom, decodeState, exceptionToMessage } from "./auth0Provider.ts";

interface Inputs {
    username: string;
    password: string;
}

export interface AfterLoginProps {
    onPostSignup: (result: SignupResult) => void;
}

export interface Auth0LoginFormProps extends AfterLoginProps {
    isSignup: boolean;
}

export function Auth0LoginForm({ isSignup, onPostSignup }: Auth0LoginFormProps) {
    //#region Hooks
    const form = useForm<Inputs>({ defaultValues: {} });
    const authProvider = useAtomValue(auth0ProviderAtom);
    const [error, setError] = useState<string | null>(null);
    //#endregion

    const onSubmit: SubmitHandler<Inputs> = async ({ username, password }) => {
        const redirectPath = getCurrentPath();
        try {
            if (isSignup) {
                const result = await authProvider.signup({ username, password, redirectPath });
                onPostSignup(result);
            } else {
                await authProvider.login({ username, password, redirectPath });
            }
        } catch (error) {
            setError(exceptionToMessage(error));
        }
    };

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <InputRow name="username" label="Username" type="username" autoComplete="username" autoFocus />
            <InputRow name="password" label="Password" type="password" autoComplete="password" />

            <WSubmitButton
                size="lg"
                fontSize="font-size-subtitle"
                className={L.Button}
                action={undefined}
                isLoading={form.formState.isSubmitting}
            >
                {isSignup ? "Sign Up" : "Log In"}
            </WSubmitButton>

            {error && (
                <div className="react-aria-FieldError pt-2">
                    <b>Error:</b> {error}
                </div>
            )}
        </WHookForm>
    );
}

function getReturnPath({ state }: Auth0CallbackResult) {
    return decodeState(state) ?? "/";
}

export function Auth0Callback({ params }: { params: Auth0CallbackResult }) {
    const content = !isAuth0Error(params) ? <Auth0DoLogin params={params} /> : <Auth0ShowError params={params} />;

    return <LoginLayout>{content}</LoginLayout>;
}

function Auth0ShowError({ params }: { params: Auth0CallbackError }) {
    const { error, error_description } = params;
    const returnPath = getReturnPath(params);

    // XXX: Can not use router links here since login does not have a router
    const backLink = <WButton className={L.More} action={getRelativeUrl(returnPath)} label="Back" />;

    if (error === "access_denied" && /\bverify\b.*\bemail\b/i.test(error_description)) {
        return (
            <LoginBox title="Action required">
                <div className="flex flex-col items-center gap-4">
                    <div>{error_description}</div>
                    {backLink}
                </div>
            </LoginBox>
        );
    } else {
        return (
            <LoginBox title="Login error">
                <div className="flex flex-col items-center gap-4">
                    <div>
                        <b>Code:</b> {error}
                    </div>
                    <div>{error_description}</div>
                    {backLink}
                </div>
            </LoginBox>
        );
    }
}

function Auth0DoLogin({ params }: { params: Auth0CallbackSuccess }) {
    const autoProceed = isBuiltRelease;

    //#region Hooks
    const store = useStore();

    const loginMutation = useSimpleMutation(async () => {
        const client = store.get(unauthApiClientAtom);

        // Forward the Auth0 data to the backend
        const data = getResponseData(
            await client.POST("/user-resource/auth0_callback", {
                ...jsonEncodedBody,
                body: params,
            }),
        );

        // Log in with the returned API key
        // (show debug information in dev mode)
        if (autoProceed) {
            onLogin(data.apikey);
        }
        return data;
    });

    useEffect(() => {
        if (loginMutation.state === "idle") {
            loginMutation.mutate();
        }
    }, [loginMutation]);

    //#endregion

    function onLogin(apikey: string) {
        store.set(apikeyAtom, apikey);
        const targetUrl = getRelativeUrl(getReturnPath(params));
        window.history.replaceState({}, "", targetUrl);
    }

    function enterTheZone() {
        invariant(loginMutation.state === "hasData");
        onLogin(loginMutation.data.apikey);
    }

    // Show JSON data only if there was an error or if we are in dev mode
    const json = loginMutation.state === "hasError" || !autoProceed ? JSON.stringify(params, null, 2) : undefined;
    return (
        <LoginBox title="Logging in...">
            <div className="children:mb-4">
                {loginMutation.state === "loading" && <Loading icon="none" />}
                {loginMutation.state === "hasError" && (
                    <div className="color-text bg-red-1 rounded p-2">{errorToString(loginMutation.error)}</div>
                )}
                {loginMutation.state === "hasData" && !autoProceed && (
                    <WButton color="primary" label="Enter the zone" action={enterTheZone} />
                )}
                {!!json && (
                    <div className="color-text bg-grey-1 rounded p-2">
                        <pre className="text-nowrap overflow-x-scroll m-0 mb-2 p-1 rounded b b-solid b-gray-3">
                            {json}
                        </pre>
                        <ClipBoardTooltip text={json}>Copy</ClipBoardTooltip>
                    </div>
                )}
            </div>
        </LoginBox>
    );
}
