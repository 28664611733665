import { atomWithRefresh } from "jotai/utils";
import { getDefaultStore } from "jotai/vanilla";
import { siteThemeAtom } from "../../config.ts";
import { addThemeListener } from "./allThemes.ts";
import { getTheme } from "./getTheme.ts";

console.debug("Loading theme store...");

export const themeAtom = atomWithRefresh((get) => getTheme(get(siteThemeAtom)));

export function forceRefreshThemeAtom() {
    console.debug("Forcing refresh of theme atom");
    getDefaultStore().set(themeAtom);
}

import.meta.hot?.dispose(addThemeListener(forceRefreshThemeAtom));
