import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { successResponse, tagPaths } from "../util.ts";

extendZodWithOpenApi(z);

//#region Tracking
export const GoogleTagManagerKey = z
    .object({
        gtm_key: z.string(),
    })
    .openapi({ ref: "GoogleTagManagerKey" });
//#endregion

const gtm_key_query = z.string().openapi({ param: { name: "gtm_key", in: "query" } });

export const trackingPaths = tagPaths("admin_tracking")({
    "/config/ui/gtm_key": {
        get: {
            summary: "Get Google Tag Manager key",
            responses: { ...successResponse(GoogleTagManagerKey) },
        },
        put: {
            summary: "Update Google Tag Manager key",
            parameters: [gtm_key_query],
            responses: { ...successResponse(GoogleTagManagerKey) },
        },
    },
});
