import { atom } from "jotai/vanilla";
import { atomWithLocalStorage } from "../../utils/jotai/atomStorage.ts";
import { mediaQueryMatcherAtom } from "./mediaQueryMatcherAtom.ts";

export type ColorSchemeValue = "light" | "dark";
export type ColorSchemeMode = ColorSchemeValue | "auto";

export const savedColorSchemeAtom = atomWithLocalStorage<ColorSchemeMode>("colorSchemeMode", "auto");

const darkModeMatcherAtom = mediaQueryMatcherAtom("(prefers-color-scheme: dark)");

export const forcedColorSchemeAtom = atom<ColorSchemeValue | undefined>(undefined);

export const currentColorSchemeAtom = atom<ColorSchemeValue>((get) => {
    const forcedColorScheme = get(forcedColorSchemeAtom);
    if (forcedColorScheme) {
        return forcedColorScheme;
    }

    const savedColorScheme = get(savedColorSchemeAtom);
    if (savedColorScheme !== "auto") {
        return savedColorScheme;
    }

    const darkModePreferred = get(darkModeMatcherAtom);
    return darkModePreferred ? "dark" : "light";
});
