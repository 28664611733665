/** Simple version of error display for eg. the root component that *must* not depend on any other modules (for bundling) */
export function errorToString(error: unknown) {
    const message = errorMessageAsString(error);
    const info = errorInfoAsString(error);
    return info ? `${message} (${info})` : message;
}

export function errorMessageAsString(error: unknown): string {
    if (error instanceof Error) {
        return error.message;
    }

    if (typeof error === "string") {
        return error;
    }

    return String(error);
}

export function errorInfoAsString(error: unknown) {
    if (error instanceof Error) {
        return error.name;
    }

    return undefined;
}
