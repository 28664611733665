import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { jsonBody, successResponse, tagPaths } from "../util.ts";

extendZodWithOpenApi(z);

//#region Referral
export const ReferralEnabled = z
    .object({
        enabled: z.boolean(),
    })
    .openapi({ ref: "ReferralEnabled" });

export const ReferralAmounts = z
    .object({
        promoter_credit: z.number(),
        receiver_credit: z.number(),
        referral_credit_receiver_precondition_topup_sum: z.number(),
    })
    .openapi({ ref: "ReferralAmounts" });
//#endregion

export const referralPaths = tagPaths("admin_referral")({
    "/payment/admin/referral_enabled": {
        get: {
            summary: "Referral enabled",
            responses: { ...successResponse(ReferralEnabled) },
        },
        put: {
            summary: "Enable/disable referral",
            requestBody: jsonBody(ReferralEnabled),
            responses: { ...successResponse(ReferralEnabled) },
        },
    },
    "/payment/admin/referral_amounts": {
        get: {
            summary: "Get referral amounts",
            responses: { ...successResponse(ReferralAmounts) },
        },
    },
});
