import type { ReactNode } from "react";

export type OptionalClassName = string | false | null | undefined;

/**
 * Concatenate any truthy {@link classNames}. Useful for conditionally applying classes.
 *
 * @example cn("a", cond && "b")
 */
export function cn(...classNames: OptionalClassName[]) {
    if (classNames.length === 1) {
        const className = classNames[0];
        return typeof className === "string" ? className : "";
    }

    let className = "";
    for (const c of classNames) {
        if (typeof c === "string" && c !== "") {
            className += c;
            className += " ";
        }
    }
    return className;
}

/** Call {@link className}({@link values}) if it is a function, otherwise just return it. */
export function cc<TV>(className: string | ((values: TV) => string | undefined) | undefined, values: TV) {
    return typeof className === "function" ? className(values) : className;
}

/** Call {@link children}({@link values}) if it is a function, otherwise just return it. */
export function callChildren<TV>(children: ReactNode | ((values: TV) => ReactNode), values: TV) {
    return typeof children === "function" ? children(values) : children;
}
