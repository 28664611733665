const NOT_RUN = Symbol.for("makeOnce/NOT_RUN");

/**
 * Wrap a function so that it will only run once (by memoizing its initial result)
 * @__NO_SIDE_EFFECTS__
 */
export function makeOnce<T extends unknown[], R>(load: (...args: T) => R) {
    let result: R | typeof NOT_RUN = NOT_RUN;

    const fn: OnceFn<T, R> = (...args) => {
        if (result !== NOT_RUN) {
            return result;
        }

        return (result = load(...args));
    };

    fn.mock = (value) => {
        result = value;
    };

    fn.mockWrap = (wrapFn) => {
        const originalLoad = load;
        load = (...args) => wrapFn(originalLoad, ...args);
    };

    fn.clear = () => {
        result = NOT_RUN;
    };

    return fn;
}

export interface OnceFn<T extends unknown[], R> {
    (...args: T): R;
    /** Replace the result with a mock */
    mock(value: R): void;
    /** Wrap the function with a mock */
    mockWrap(wrapFn: (originalFn: (...args: T) => R, ...args: T) => R): void;
    /** Clear the memoized result */
    clear(): void;
}
