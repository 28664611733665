import type { ReactNode } from "react";
import { Group } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import C from "./FormField.module.css";

export function FieldButtonGroup({
    children,
    hasAbsoluteButton,
}: {
    children: ReactNode;
    hasAbsoluteButton?: boolean;
}) {
    return <Group className={cn(C.Group, hasAbsoluteButton && C.AbsoluteButton)}>{children}</Group>;
}
