import "./chat.css";

import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { IntercomProvider } from "react-use-intercom";
import { chatConfigAtom, getGlobalConfig_UNTESTABLE } from "../../config.ts";
import { loadScript } from "../../utils/loadScript.ts";
import { getThemeVar_ONCE } from "../theme/useTheme.ts";
import type { BotikaConfig, OncehubConfig, QiscusConfig } from "./ChatConfig.types.ts";

/* eslint-disable @typescript-eslint/no-namespace */

//#region Qiscus

declare global {
    class Qismo {
        constructor(key: string, options: object);
    }
}

const qiscusLoader = makeOnce(async ({ qiscus_channel_id, qiscus_client_key }: QiscusConfig) => {
    if (!qiscus_client_key || !qiscus_channel_id) {
        console.warn("Missing Qiscus config");
        return;
    }

    await loadScript("https://s3-ap-southeast-1.amazonaws.com/qiscus-sdk/public/qismo/qismo-v4.js");

    const _ = new Qismo(qiscus_client_key, {
        options: {
            channel_id: qiscus_channel_id,
            extra_fields: [],
        },
    });
});

//#endregion

//#region Botika
declare global {
    namespace BotikaChat {
        function init(options: object): unknown;
    }
}

const botikaLoader = makeOnce(async ({ botika_client_key }: BotikaConfig, primaryColor, logoImage) => {
    const { siteName } = getGlobalConfig_UNTESTABLE();

    if (!botika_client_key) {
        console.warn("Missing Botika config");
        return;
    }

    await loadScript("//chat.botika.online/client/assets/js/botika.widget.js");

    BotikaChat.init({
        client: botika_client_key,
        widget: {
            theme: "custom",
            "theme-color": primaryColor,
            "bubble-color": primaryColor,
            "description-color": "#ffffff",
            title: siteName,
            description: "Customer support",
            caption: "Need help?",
            greeting: true,
            logo: logoImage,
            history: true,
            "greeting-message": "Hello",
            "greeting-button": "Start",
            autocomplete: "",
            registerUser: { name: true, email: true, phone: false },
            startMessage: "",
            clientCustom: "",
            buttonPayload: true,
            voice: { active: false, disabled: true },
        },
    });
});
//#endregion

//#region Oncehub
declare global {
    namespace BotikaChat {
        function init(key: string, options: object): unknown;
    }
}

const oncehubLoader = makeOnce(async ({ oncehub_website_id, oncehub_bot_id }: OncehubConfig) => {
    if (!oncehub_website_id || !oncehub_bot_id) {
        console.warn("Missing Oncehub config");
        return;
    }

    await loadScript("https://cdn.oncehub.com/co/widget.js", (script) => {
        script.id = "co-index";
        script.dataset.coParams = `website_id=${oncehub_website_id}&bot_type=2`;
    });

    const link = document.createElement("a");
    link.id = "oncechat-link";
    link.href = "#";
    link.dataset.coBot = oncehub_bot_id;
    link.setAttribute("onclick", "document.getElementById('oncechat-link').style.display='none'");
    document.body.appendChild(link);
});

//#endregion

export function WChatProvider({ children }: { children: ReactNode }) {
    //#region Hooks
    const chatConfig = useAtomValue(chatConfigAtom);
    //#endregion

    if (!chatConfig) {
        return children;
    }

    const primaryColor = getThemeVar_ONCE("color-primary");
    const logoImage = getThemeVar_ONCE("logo-image");

    const { type } = chatConfig;
    switch (type) {
        case "intercom": {
            return (
                <IntercomProvider
                    appId={chatConfig.intercom_app_id}
                    initializeDelay={1000}
                    autoBoot
                    autoBootProps={{
                        alignment: "left",
                        actionColor: primaryColor,
                        backgroundColor: primaryColor,
                    }}
                >
                    {children}
                </IntercomProvider>
            );
        }
        case "qiscus": {
            discardPromise(qiscusLoader(chatConfig));
            break;
        }
        case "botika": {
            discardPromise(botikaLoader(chatConfig, primaryColor, logoImage));
            break;
        }
        case "oncehub": {
            discardPromise(oncehubLoader(chatConfig));
            break;
        }
        default: {
            console.warn("Unsupported chat provider type", type);
            break;
        }
    }

    return children;
}
