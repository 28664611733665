import { discardPromise, discardPromiseFn } from "@warrenio/utils/promise/discardPromise";
import { useEffect } from "react";
import { useThemeProps } from "./modules/theme/useTheme.ts";
import { loadScript } from "./utils/loadScript.ts";

const loadedScripts = new Map<string, Promise<Event>>();

function loadScriptOnce(src: string) {
    let promise = loadedScripts.get(src);
    if (!promise) {
        promise = loadScript(src);
        loadedScripts.set(src, promise);
    }
    return promise;
}

export function ScriptLoader() {
    const { themeScripts } = useThemeProps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        discardPromiseFn(async () => {
            for (const { src, waitForLoad = false } of themeScripts ?? []) {
                const promise = loadScriptOnce(src);
                if (waitForLoad) {
                    await promise;
                } else {
                    discardPromise(promise);
                }
            }
        }),
        [themeScripts],
    );
    return null;
}
