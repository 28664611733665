import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useCallback, useState } from "react";
import { isPromise } from "remeda";

export interface PromiseLoadingHook {
    isPromiseLoading: boolean;
    registerPromise: (promise: unknown) => void;
}

export function usePromiseLoading(): PromiseLoadingHook {
    const [promisePendingCount, setPromisePendingCount] = useState(0);
    const registerPromise = useCallback((promise: unknown) => {
        if (!isPromise(promise)) {
            return;
        }

        setPromisePendingCount((count) => count + 1);
        discardPromise(
            Promise.prototype.finally.call(promise, () => {
                setPromisePendingCount((count) => count - 1);
            }),
        );
    }, []);

    return { isPromiseLoading: promisePendingCount > 0, registerPromise };
}
