// NB: This does not use a TypeScript `enum` since:
// - regular `enum`s do not work for `switch` exhaustiveness checking
// - `const enum`s do not work for schema generation due to TypeScript bugs
//     - see: [isolatedModules doesn't respect enabled preserveConstEnum option](https://github.com/microsoft/TypeScript/issues/37774)
// - see also: [With or without enums in TypeScript](https://blog.beraliv.dev/2022-09-10-with-or-without-enums)

export const InvoiceStatusEnum = {
    IN_PROGRESS: 0,
    UNPAID: 5,
    PAID: 10,
} as const;
