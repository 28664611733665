export interface Auth0CallbackSuccess {
    access_token: string;
    id_token: string;
    scope: string;
    expires_in: string;
    token_type: string;

    state: string;

    error?: never;
}

// /oauth_callback?error=access_denied&error_description=Please%20verify%20your%20email%20before%20continuing.&state=
export interface Auth0CallbackError {
    error: string;
    error_description: string;

    state: string;
}

export type Auth0CallbackResult = Auth0CallbackSuccess | Auth0CallbackError;

export function isAuth0Error(params: Auth0CallbackResult): params is Auth0CallbackError {
    return "error" in params;
}

/** Check if the current URL has Auth0 callback params in the hash, and return them. */
export function getAuth0CallbackParams() {
    const { hash } = document.location;
    const params = new URLSearchParams(hash.slice(1));
    if (params.has("error") || params.has("state")) {
        return Object.fromEntries(params.entries()) as object as Auth0CallbackResult;
    }
    return null;
}
