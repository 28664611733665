import { z, type ZodTypeAny } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { isAscending } from "../isAscending.ts";
import { errorResponse, jsonBody, successResponse, tagPaths, unref } from "../util.ts";
import {
    billing_account_id,
    created_at,
    datetime,
    deleted_at,
    int,
    mac,
    simpleSuccessResponse,
    updated_at,
    user_id,
    uuid,
} from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Field types

const string_id = z.string().regex(/^[a-z][a-z0-9_\-]*$/);

const is_deleted = z.boolean().describe("Whether this entity has been deleted");

export const MetalOsId = string_id.describe("Operating system ID").openapi({ ref: "MetalOsId" });
export const MetalOsTypeId = string_id.describe("Operating system type ID").openapi({ ref: "MetalOsTypeId" });

//#endregion

//#region Parameters

const os_id_param = MetalOsId.openapi({ param: { name: "os_id", in: "path", ref: "os_id" } });
const os_type_id_param = MetalOsTypeId.openapi({ param: { name: "os_type_id", in: "path", ref: "os_type_id" } });

//#endregion

//#region Internal / admin data model

///---- Conventions -----

// The types named `*Fields` are parts of an entity that can be created/updated trivially via admin

///----- Spec -----

const MetalSpecFields = z
    .object({
        title: z.string(),
        subtitle: z.string(),
        description: z.string(),

        is_visible: z.boolean().describe("Whether this spec is visible when creating a machine"),
    })
    .openapi({ ref: "MetalSpecFields" });

export const MetalSpec = MetalSpecFields.extend({
    spec_id: int,
    uuid,

    created_at,
    updated_at,
    deleted_at,

    /** Soft delete since history entries refer to this entity */
    is_deleted,
})
    .describe("A group of machines with similar hardware characteristics")
    .openapi({ ref: "MetalSpec" });

///----- Machine -----

const MetalMachineFields = z
    .object({
        label: z.string(),

        spec_id: int,
        mac_addresses: z.array(mac).describe("List of MAC addresses of the main network card"),
        ip_public_v4: z.string().ip("v4"),
        ip_public_v6: z.string().ip("v6").optional().nullable(),

        ssh_credentials: z.string().optional().nullable(),

        admin_notes: z.string().optional().nullable().describe("Internal notes about the machine"),
    })
    .openapi({ ref: "MetalMachineFields" });

export const MetalMachine = MetalMachineFields.extend({
    machine_id: int,
    uuid,

    // NB: These fields apply only to updates to the machine, not to the state
    created_at,

    current_state_id: int.describe("FK to the latest state in the history"),
})
    .describe("A physical machine")
    .openapi({ ref: "MetalMachine" });

///----- Lease -----

export const MetalLeaseFields = z
    .object({
        requested_os_id: MetalOsId,
        display_name: z.string(),
    })
    .openapi({ ref: "MetalLeaseFields" });

export const MetalLease = MetalLeaseFields.extend({
    lease_id: int,
    uuid,

    machine_id: int,
    user_id,

    created_at,
    updated_at,
})
    .describe("The ownership of a machine by a specific user (for a period of time)")
    .openapi({ ref: "MetalLease" });

///----- State -----

//#region Status enum and its subsets
export const MetalStatus = z
    .enum(["available", "pending", "in_use", "cleaning", "offline", "deleted"])
    .openapi({
        example: "available",
        ref: "MetalStatus",
    })
    .describe("States for a machine");

export const MetalLeasedStatus = z
    .enum(["pending", "in_use"])
    .openapi({
        example: "in_use",
        ref: "MetalLeasedStatus",
    })
    .describe("Valid states for a machine that is currently leased. Subset of `MetalStatus`.");

export const MetalUnleasedStatus = z
    .enum(["available", "cleaning", "offline"])
    .openapi({
        ref: "MetalUnleasedStatus",
    })
    .describe("Valid states for a machine that is not currently leased. Subset of `MetalStatus`.");
//#endregion

const MetalMachineStateFields = z
    .object({
        lease_id: int.optional().nullable(),
        billing_account_id: billing_account_id.optional().nullable(),
        os_id: MetalOsId.nullable(),
        status: MetalStatus,
    })
    .describe("Fields that should be part of a machine but are tracked separately (for h");

// NB: Need to put the fields in a separate object because Zod's `refine()` does not allow using `.pick()`: https://github.com/colinhacks/zod/issues/1245
const MetalStateHistory_Raw = MetalMachineStateFields.extend({
    state_id: int,
    changed_at: datetime,

    acting_user_id: unref(user_id).describe("User who initiated the change"),
    action: z.enum([
        "admin_create",
        "admin_update",
        "admin_delete",
        "admin_lease_create",
        "user_request",
        "user_release",
    ]),

    machine_id: int,
});

export const MetalStateHistory = MetalStateHistory_Raw
    // Check invariants
    .refine(
        (s) => s.lease_id == null || (s.status !== "available" && s.status !== "offline" && s.status !== "deleted"),
        (s) => ({ path: ["status"], message: `Machine must not be leased in "${s.status}" status` }),
    )
    .refine(
        (s) => (s.status !== "in_use" && s.status !== "pending") || s.lease_id != null,
        "In-use machine must be leased",
    )
    .refine((s) => s.lease_id == null || s.billing_account_id != null, "Leased machine must have a billing account")
    .refine(
        (s) => s.lease_id != null || s.billing_account_id == null,
        "Unleased machine must not have a billing account",
    )
    .describe("Current and previous states of machines")
    .openapi({ ref: "MetalStateHistory" });

///----- OS -----

const MetalOsFields = z
    .object({
        os_type_id: MetalOsTypeId,
        is_published: z.boolean().describe("Whether this OS is visible when creating a machine"),
        version_title: z.string(),
    })
    .openapi({ ref: "MetalOsFields" });

export const MetalOs = MetalOsFields.extend({
    os_id: MetalOsId,

    is_deleted,
    updated_at,
})
    .describe("An operating system that can be installed on a machine (specific version)")
    .openapi({ ref: "MetalOs" });

export const MetalOsCreate = MetalOsFields.extend({
    os_id: MetalOs.shape.os_id,
}).openapi({ ref: "MetalOsCreate" });

export const MetalOsUpdate = MetalOsFields.extend({}).partial().openapi({ ref: "MetalOsUpdate" });

///----- OS type -----

const MetalOsTypeFields = z.object({
    title: z.string(),
    icon: z.string(),
});

export const MetalOsType = MetalOsTypeFields.extend({
    os_type_id: MetalOsTypeId,

    is_deleted,
    updated_at,
})
    .describe("A category of operating systems (eg. CentOS, Debian, Windows)")
    .openapi({ ref: "MetalOsType" });

export const MetalOsTypeCreate = MetalOsTypeFields.extend({
    os_type_id: MetalOsType.shape.os_type_id,
}).openapi({ ref: "MetalOsTypeCreate" });

export const MetalOsTypeUpdate = MetalOsTypeFields.extend({}).partial().openapi({ ref: "MetalOsTypeUpdate" });

//#endregion

export const metalSchemas: Record<string, ZodTypeAny> = {
    MetalStateHistory,
};

//#region Public / user frontend data model

//#region Queries
export const MetalListItem = MetalLease.extend({
    spec: MetalSpec.pick({
        spec_id: true,
        uuid: true,

        title: true,
        subtitle: true,
        description: true,
    }).describe("Machine hardware specification"),

    os_id: MetalStateHistory_Raw.shape.os_id,
    billing_account_id,

    ...MetalMachine.pick({
        ssh_credentials: true,
        ip_public_v4: true,
        ip_public_v6: true,
        mac_addresses: true,
    }).shape,

    status: MetalLeasedStatus,
})
    .describe("Machine viewed from the perspective of a single user (used in lists and detail view)")
    .openapi({ ref: "MetalListItem" });

export const MetalListResponse = z.array(MetalListItem).openapi({ ref: "MetalListResponse" });

export const MetalOsListResponse = z
    .object({
        os_types: z.array(MetalOsType),
        os: z.array(MetalOs),
    })
    .openapi({ ref: "MetalOsListResponse" });

export const MetalSpecListItem = MetalSpec.extend({
    is_available: z.boolean().describe("Whether machines with this spec can be created right now"),
    ready_os_ids: z
        .array(MetalOsId)
        .describe("List of OS IDs that are immediately available for machines with this spec"),
}).openapi({ ref: "MetalSpecListItem" });

export const MetalSpecListResponse = z.array(MetalSpecListItem).openapi({ ref: "MetalSpecListResponse" });
//#endregion

//#region Mutations
export const MetalLeaseCreateBody = z
    .object({
        spec_uuid: MetalSpec.shape.uuid,
        display_name: MetalLease.shape.display_name,
        requested_os_id: MetalLease.shape.requested_os_id,
        billing_account_id,
    })
    .openapi({ ref: "MetalLeaseCreateBody" });

export const MetalLeaseUpdateBody = z
    .object({
        display_name: MetalLease.shape.display_name,
    })
    .openapi({ ref: "MetalLeaseUpdateBody" });
//#endregion

//#region Admin mutations

const AdminMetalMachineStateFields = z
    .object({
        os_id: MetalStateHistory_Raw.shape.os_id,
    })
    .describe("Fields for a machine's entry in the State table");

// A machine will always be created with its initial state
export const AdminMetalMachineCreateBody = MetalMachineFields.extend(AdminMetalMachineStateFields.shape).openapi({
    ref: "AdminMetalMachineCreateBody",
});

export const AdminMetalMachineUpdateBody = MetalMachineFields.extend(AdminMetalMachineStateFields.shape)
    .extend({
        status: MetalStateHistory_Raw.shape.status,
    })
    .partial()
    .openapi({
        ref: "AdminMetalMachineUpdateBody",
    });

/////

export const AdminMetalSpecCreateBody = MetalSpecFields.extend({}).openapi({
    ref: "AdminMetalSpecCreateBody",
});

export const AdminMetalSpecUpdateBody = MetalSpecFields.extend({})
    .partial()
    .openapi({ ref: "AdminMetalSpecUpdateBody" });

/////

export const AdminMetalLeaseCreateBody = MetalLeaseFields.extend({
    machine_id: MetalLease.shape.machine_id,
    billing_account_id,
}).openapi({ ref: "AdminMetalLeaseCreateBody" });

export const AdminMetalLeaseUpdateBody = MetalLeaseFields.extend({})
    .partial()
    .openapi({ ref: "AdminMetalLeaseUpdateBody" });
//#endregion

//#region Admin queries

export const AdminMetalMachineItem = MetalMachine.merge(MetalMachineStateFields)
    .extend({
        // Joins for UI convenience (emulates GraphQL)
        spec: MetalSpec,
        lease: MetalLease.optional(),
    })
    .openapi({ ref: "AdminMetalMachineItem" });

const AdminMetalMachineList = z.array(AdminMetalMachineItem).openapi({ ref: "AdminMetalMachineList" });

/////
const AdminMetalSpecList = z.array(MetalSpec).openapi({ ref: "AdminMetalSpecList" });

/////

const AdminMetalHistoryItem = MetalStateHistory_Raw.extend({
    // Joins for UI convenience
    lease: MetalLease.optional(),
}).openapi({ ref: "AdminMetalHistoryItem" });

const AdminMetalHistoryList = z.array(AdminMetalHistoryItem).openapi({ ref: "AdminMetalHistoryList" });

//#endregion

//#region Service queries (for eg. billing) - not used in UI / admin

const MetalChargingEntry = z
    .object({
        changed_at: MetalStateHistory_Raw.shape.changed_at,

        // These fields can change during the lease duration so they can not be part of `MetalChargingItem`
        billing_account_id,
        spec_uuid: MetalSpec.shape.uuid,

        is_active: z.boolean().describe("Whether the lease is (was) active and should be billed"),
    })
    .openapi({ ref: "MetalChargingEntry" })
    .describe("Charging ledger entry");

const MetalChargingEntries = z
    .array(MetalChargingEntry)
    .min(1)
    .refine((entries) => isAscending(entries, (e) => e.changed_at), "Entries must be sorted by `changed_at`");

const MetalChargingItem = z
    .object({
        lease_uuid: MetalLease.shape.uuid,
        user_id: MetalLease.shape.user_id, // (just in case)

        // Metadata for invoices
        display_name: MetalLease.shape.display_name,

        entries: MetalChargingEntries,
    })
    .openapi({ ref: "MetalChargingItem" })
    .describe("Charging entries grouped by lease");

const MetalChargingList = z
    .array(MetalChargingItem)
    .openapi({ ref: "MetalChargingList" })
    .describe("Input to charging service (billing system)");

//#endregion

export const metalPaths = tagPaths("metal")({
    //#region User
    "/{location}/metal/leases": {
        get: {
            description: "List of machines leased by the user",
            parameters: [params.location],
            responses: { ...successResponse(MetalListResponse) },
        },
        post: {
            description: "Request to lease a machine with a specific spec",
            parameters: [params.location],
            requestBody: { ...jsonBody(MetalLeaseCreateBody) },
            responses: {
                ...successResponse(MetalListItem),
                ...errorResponse("No available machines"),
            },
        },
    },
    "/{location}/metal/leases/{uuid}": {
        patch: {
            description: "Update lease details",
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(MetalLeaseUpdateBody) },
            // TODO: Should probably return the updated `MetalListItem`
            responses: { ...simpleSuccessResponse },
        },
        delete: {
            description: "Release a machine",
            parameters: [params.location, params.uuid],
            responses: {
                ...simpleSuccessResponse,
                ...errorResponse("Too soon to release machine"),
            },
        },
    },
    "/{location}/metal/specs": {
        get: {
            parameters: [params.location],
            responses: { ...successResponse(MetalSpecListResponse) },
        },
    },
    "/metal/os": {
        get: {
            responses: { ...successResponse(MetalOsListResponse) },
        },
    },
    //#endregion
});

export const metalAdminPaths = tagPaths("admin_metal")({
    // Machines
    "/{location}/admin/metal/machines": {
        get: {
            parameters: [params.location],
            responses: { ...successResponse(AdminMetalMachineList) },
        },
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalMachineCreateBody) },
            responses: { ...successResponse(MetalMachine) },
        },
    },
    "/{location}/admin/metal/machines/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalMachineUpdateBody) },
            responses: { ...successResponse(MetalMachine) },
        },
        delete: {
            parameters: [params.location, params.uuid],
            responses: { ...simpleSuccessResponse },
        },
    },

    // State history
    "/{location}/admin/metal/machines/{uuid}/history": {
        get: {
            parameters: [params.location, params.uuid],
            responses: { ...successResponse(AdminMetalHistoryList) },
        },
    },

    // Leases
    "/{location}/admin/metal/leases": {
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalLeaseCreateBody) },
            responses: { ...successResponse(MetalLease) },
        },
    },
    "/{location}/admin/metal/leases/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalLeaseUpdateBody) },
            responses: { ...successResponse(MetalLease) },
        },
    },

    // Specs
    "/{location}/admin/metal/specs": {
        get: {
            parameters: [params.location],
            responses: { ...successResponse(AdminMetalSpecList) },
        },
        post: {
            parameters: [params.location],
            requestBody: { ...jsonBody(AdminMetalSpecCreateBody) },
            responses: { ...successResponse(MetalSpec) },
        },
    },
    "/{location}/admin/metal/specs/{uuid}": {
        patch: {
            parameters: [params.location, params.uuid],
            requestBody: { ...jsonBody(AdminMetalSpecUpdateBody) },
            responses: { ...successResponse(MetalSpec) },
        },
        delete: {
            parameters: [params.location, params.uuid],
            responses: { ...simpleSuccessResponse },
        },
    },

    // Operating systems
    // NB: Listing OS types and OSes is already part of the user-facing API
    "/admin/metal/os": {
        post: {
            requestBody: { ...jsonBody(MetalOsCreate) },
            responses: { ...successResponse(MetalOs) },
        },
    },
    "/admin/metal/os/{os_id}": {
        patch: {
            parameters: [os_id_param],
            requestBody: { ...jsonBody(MetalOsUpdate) },
            responses: { ...successResponse(MetalOs) },
        },
        delete: {
            parameters: [os_id_param],
            responses: { ...simpleSuccessResponse },
        },
    },

    // OS types
    "/admin/metal/os_types": {
        post: {
            requestBody: { ...jsonBody(MetalOsTypeCreate) },
            responses: { ...successResponse(MetalOsType) },
        },
    },
    "/admin/metal/os_types/{os_type_id}": {
        patch: {
            parameters: [os_type_id_param],
            requestBody: { ...jsonBody(MetalOsTypeUpdate) },
            responses: { ...successResponse(MetalOsType) },
        },
        delete: {
            parameters: [os_type_id_param],
            responses: { ...simpleSuccessResponse },
        },
    },

    //#endregion

    //#region Services
    // TODO: What should the path prefix be here? "internal", "service", ...?
    "/{location}/internal/metal/charging": {
        get: {
            description: "Get charging ledger entries for the billing system for all machines",
            parameters: [params.location],
            responses: { ...successResponse(MetalChargingList) },
        },
    },
    //#endregion
});
