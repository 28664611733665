import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import type { Omise } from "omise-js-typed";
import type {
    OmiseCreateResponseSuccessful,
    OmiseCreateSourceCallback,
    OmiseCreateSourceCallbackSuccessful,
    OmiseCreateSourceParameters,
    OmiseTokenParameters,
} from "omise-js-typed/dist/lib/omise";
import type { OmiseResponseFailure, Payment } from "omise-js-typed/dist/lib/utils";
import { useEffect } from "react";
import { isMockApiEnabled } from "../../../mock-api/msw/mswInit.ts";
import { isTestEnvironment } from "../../../utils/environment.ts";
import { loadScript } from "../../../utils/loadScript.ts";
import { makeMockOmise } from "./mockOmise.ts";

export const omiseLoader = makeOnce(async () => {
    if (import.meta.env.DEV && (isTestEnvironment || isMockApiEnabled())) {
        return makeMockOmise();
    }

    await loadScript("https://cdn.omise.co/omise.js");
    return window.Omise;
});

export function usePreloadOmise() {
    useEffect(() => {
        discardPromise(omiseLoader());
    }, []);
}

export interface FixedOmiseTokenParameters {
    city?: string;
    country?: string;
    expiration_month: number | string;
    expiration_year: number | string;
    name: string;
    number: number | string;
    phone_number?: number | string;
    postal_code?: number | string;
    security_code?: number | string;
    state?: string;
    street1?: string;
    street2?: string;
}

/** Promise-based wrapper around {@link Omise.createSource} */
export function omiseCreateSource(omise: Omise, type: Payment, sourceParameters: OmiseCreateSourceParameters) {
    return new Promise<OmiseCreateSourceCallbackSuccessful>((resolve, reject) =>
        omise.createSource(type, sourceParameters, (status, response) =>
            rejectOnError(reject, resolve, status, response as OmiseCreateSourceCallback),
        ),
    );
}

/** Promise-based wrapper around {@link Omise.createToken} */
export function omiseCreateToken(omise: Omise, type: string, tokenParameters: FixedOmiseTokenParameters) {
    return new Promise<OmiseCreateResponseSuccessful>((resolve, reject) =>
        omise.createToken(type, tokenParameters as OmiseTokenParameters, (status, response) =>
            rejectOnError(reject, resolve, status, response),
        ),
    );
}

function rejectOnError<T extends OmiseResponseFailure>(
    reject: (reason?: unknown) => void,
    resolve: (value: T) => void,
    status: number,
    response: T,
) {
    if (status !== 200) {
        reject(new Error(`Omise API error (${status}): ${response.message} [${response.code}]`));
    }
    resolve(response);
}
