import type { ReactElement } from "react";
import { mcn, type BaseProps } from "../../utils/baseProps";
import { MaskIcon } from "./MaskIcon";

/** Icon's classname or React element */
export type IconProperty = string | ReactElement;

/** Component that normalizes an `icon` of type {@link IconProperty} into a {@link MaskIcon} or a wrapper `span` */
export function IconWrapper({ icon, ...props }: { icon: IconProperty } & BaseProps) {
    if (typeof icon === "string") {
        return <MaskIcon {...mcn(icon, props)} />;
    }
    return <span {...props}>{icon}</span>;
}
