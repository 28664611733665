import type { Simplify } from "type-fest";
import type { UndefinedToOptional } from "./utilTypes.ts";

/** Information about a set of variants for a component */
export interface VariantInfo {
    variants: Variants;
    defaults: Record<string, unknown>;
}

/** Field -> an array of possible values */
export type Variants = Record<string, readonly unknown[]>;

/** Convert an object with variant arrays to a union type of all possible values */
export type VariantInfoProps<TInfo extends VariantInfo, T extends Variants = TInfo["variants"]> = Simplify<
    // Convert undefined values to optional fields
    UndefinedToOptional<
        {
            // Convert array values to union type
            -readonly [K in keyof T]: T[K][number];
        },
        // Make any fields with defaults be optional
        keyof TInfo["defaults"]
    >
>;

/**
 * Typed wrapper for a {@link VariantInfo} object
 * @__NO_SIDE_EFFECTS__
 */
export function vi<const TInfo extends VariantInfo>(info: TInfo) {
    return info;
}

export function getVariants<TInfo extends VariantInfo, const TKey extends keyof TInfo["variants"]>(
    info: TInfo,
    key: TKey,
) {
    const values = info.variants[key as string] as VariantInfoProps<TInfo>[TKey][];
    const hasDefault = key in info.defaults;
    if (hasDefault) {
        return values.filter((v) => v !== undefined);
    }
    return values;
}

export function getAllVariants<TInfo extends VariantInfo>(info: TInfo) {
    return Object.fromEntries(Object.keys(info.variants).map((k) => [k, getVariants(info, k)])) as TInfo["variants"];
}
