export class NotFoundError extends Error {
    name = "NotFoundError";
    constructor(
        public readonly objectName: string,
        public readonly key: unknown,
    ) {
        super(`${objectName} ${key} not found`);
    }
}

export function getAndAssert<T, TKey>(map: Map<TKey, T>, key: TKey, objectName: string): T {
    const value = map.get(key);
    if (!value) {
        throw new NotFoundError(objectName, key);
    }
    return value;
}

export function getAndAssertObj<T, TKey extends string>(map: Record<TKey, T>, key: TKey, objectName: string): T {
    // Only own properties are considered
    if (!Object.prototype.hasOwnProperty.call(map, key)) {
        throw new NotFoundError(objectName, key);
    }
    return map[key];
}
