import { link } from "../../components/Action.tsx";
import type { IpAddressWithType } from "./ipAddress/apiOperations.ts";
import type { LoadBalancerWithType } from "./loadbalancer/apiOperations.ts";
import type { VpcWithType } from "./vpc/apiOperations.ts";

export function vpcLink({ location, uuid }: VpcWithType) {
    return link({ to: "/network/vpc/$location/$uuid", params: { location, uuid } });
}

export function loadBalancerLink({ location, uuid }: LoadBalancerWithType) {
    return link({ to: "/network/load_balancer/$location/$uuid", params: { location, uuid } });
}

export function ipAddressLink({ location, uuid }: IpAddressWithType) {
    return link({ to: "/network/ip_address/$location/$uuid", params: { location, uuid } });
}
