import { z, type ZodRawShape } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, successResponse } from "../util.ts";
import { created_at, uuid } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Host Pool
const HostPoolFields = {
    created_at,
    description: z.string().optional(),
    is_default_designated: z.boolean(),
    name: z.string(),
    storage_pool_uuid: z.string(),
    ui_position: z.number(),
    updated_at: created_at.optional(),
    uuid,
} as const satisfies ZodRawShape;

export const HostPool = z.object(HostPoolFields).openapi({ ref: "HostPool" });
//#endregion

export const HostPoolResponse = z.array(HostPool);

export const hostPoolPaths: ZodOpenApiPathsObject = {
    "/{location}/user-resource/host_pool/list": {
        get: {
            tags: ["host_pool"],
            summary: "List Host Pools",
            parameters: [params.location],
            responses: {
                ...successResponse(HostPoolResponse),
                ...errorResponse(`Failed to list host pools from ${params.location}`),
            },
        },
    },
};
