import type { paths } from "@warrenio/api-spec/spec.oats.gen";
import isNetworkError from "is-network-error";
import { atom } from "jotai/vanilla";
import createClient from "openapi-fetch";
import invariant from "tiny-invariant";
import { isMockApiEnabled } from "../../mock-api/msw/mswInit.ts";
import { isBuiltRelease, isTestEnvironment } from "../../utils/environment.ts";
import { fetchWithMock } from "../../utils/fetchClient.ts";
import { atomWithLocalStorage } from "../../utils/jotai/atomStorage.ts";
import type { ApiClient } from "./apiClient.ts";
import { ApiConnectionError } from "./ApiError.ts";
import { registerSchemaCheckMiddleware } from "./schemaCheck.ts";

/** NB: Without trailing slash */
export function getApiUrl(apiPrefix: string) {
    invariant(!apiPrefix.endsWith("/"), "apiPrefix must not end with a slash");
    return `${window.location.origin}${apiPrefix}`;
}

export function getWebsocketUrl(apiPrefix: string) {
    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    return `${wsProtocol}://${window.location.host}${apiPrefix}`;
}

export interface ApiParameters {
    apikey: string | undefined;
    apiPrefix: string;
}

export function createApiClient({ apikey, apiPrefix }: ApiParameters, preflightCheck?: () => Promise<void>): ApiClient {
    const client = createClient<paths>({
        fetch: async (...args) => {
            invariant(!isTestEnvironment || isMockApiEnabled(), "Mock API must be enabled in test environment");

            await preflightCheck?.();

            try {
                return await fetchWithMock(...args);
            } catch (e) {
                if (isNetworkError(e)) {
                    throw new ApiConnectionError(e);
                }
                throw e;
            }
        },
        bodySerializer: (_body) => {
            throw new TypeError("Body serializer must be set, see eg. `urlEncodedBody`");
        },
        baseUrl: getApiUrl(apiPrefix),
        headers: apikey != null ? { apikey } : {},
    });
    registerSchemaCheckMiddleware(client);
    return client;
}

export const canSelectApiPrefix = !isBuiltRelease && !isTestEnvironment;
const defaultApiPrefix = "/v1";

export const apiPrefixAtom = canSelectApiPrefix
    ? atomWithLocalStorage("devApiPrefix", defaultApiPrefix)
    : atom(defaultApiPrefix);

/** An API client for unauthenticated (logged out) requests */
export const unauthApiClientAtom = atom(
    (get): ApiClient =>
        createApiClient({
            apiPrefix: get(apiPrefixAtom),
            apikey: undefined,
        }),
);
