import { atom } from "jotai/vanilla";
import { isTestEnvironment } from "../../utils/environment.ts";
import { auth0DomainCheckAtom } from "./auth0/auth0Config.ts";
import { auth0ProviderAtom } from "./auth0/auth0Provider.ts";

export interface RedirectParams {
    /** NB: Only allow specifying a relative path to prevent open redirect attacks */
    redirectPath?: string;
}

export interface SignupParams extends RedirectParams {
    username: string;
    password: string;
}

export interface SignupResult {
    email: string | undefined;
    emailVerified: boolean;
}

export interface LoginParams extends RedirectParams {
    username: string;
    password: string;
}

export interface ForgotPasswordParams extends RedirectParams {
    email: string;
}

export interface SocialAuthParams extends RedirectParams {}

export interface LogoutParams extends RedirectParams {}

export interface AuthProvider {
    signup(params: SignupParams): Promise<SignupResult>;

    login(params: LoginParams): Promise<void>;
    googleAuth(params: SocialAuthParams): Promise<void>;
    logout(params: LogoutParams): Promise<void>;

    forgotPassword(params: ForgotPasswordParams): Promise<unknown>;

    isFake?: true;
}

function logAlert(message: string, ...args: unknown[]) {
    console.log(message, ...args);
    if (!isTestEnvironment) {
        alert(message);
    }
}

export const fakeAuthProvider: AuthProvider = {
    isFake: true,

    /* eslint-disable @typescript-eslint/require-await */
    async signup(p) {
        logAlert("Fake signup", p);
        return { email: "user@example.com", emailVerified: false };
    },
    async login(p) {
        logAlert("Fake login", p);
    },
    async googleAuth(p) {
        logAlert("Fake google login", p);
    },
    async forgotPassword(p) {
        logAlert("Fake forgot password", p);
    },
    async logout(p) {
        console.log("Fake logout", p);
    },
    /* eslint-enable @typescript-eslint/require-await */
};

export const authProviderAtom = atom<AuthProvider>((get) => {
    const { isValid } = get(auth0DomainCheckAtom);
    return isValid ? get(auth0ProviderAtom) : fakeAuthProvider;
});
