import { useSetAtom } from "jotai/react";
import { useLayoutEffect } from "react";
import { forcedColorSchemeAtom } from "../main/ColorSchemeSwitcher.store.ts";
import { UniversalLoginRoot } from "./ApiKeyLogin.tsx";

export function LoginsRoot() {
    const setForcedColorScheme = useSetAtom(forcedColorSchemeAtom);
    useLayoutEffect(() => {
        setForcedColorScheme("light");
        return () => {
            setForcedColorScheme(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UniversalLoginRoot />;
}

export default LoginsRoot;
