import FF from "./FormField.module.css";

import type { ReactNode } from "react";
import { FieldError, Label, Text } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { standardFieldAttributes, type StandardFieldProps } from "./standardFieldAttributes.tsx";

export interface FormFieldProps extends StandardFieldProps, FormFieldChildrenProps {
    className?: string;
    block?: boolean;
    "data-testid"?: string;
}

/** Component for a custom form field row (as opposed to eg. WTextField) */
export function FormField({ children, className, block, "data-testid": testId, ...props }: FormFieldProps) {
    return (
        <div
            className={cn(FF.FormField, className, block && FF.Block)}
            data-testid={testId}
            {...standardFieldAttributes(props)}
        >
            <FormFieldChildren {...props}>{children}</FormFieldChildren>
        </div>
    );
}

export interface FormFieldChildrenProps {
    wide?: boolean;

    label?: ReactNode;
    children?: ReactNode;
    /** Display an error message (eg. from custom validation). */
    errorMessage?: ReactNode;
    description?: ReactNode;

    /** The id of the form field the label is for */
    htmlFor?: string;
}

/**
 * Styled row that contains a label, form field and description.
 *
 * Will display both native and custom validation errors.
 */
export function FormFieldChildren({
    children,
    description,
    errorMessage,
    label,
    htmlFor,
    wide,
}: FormFieldChildrenProps) {
    return (
        <>
            {!!label && (
                <Label className={FF.FormFieldLabel} htmlFor={htmlFor}>
                    {label}
                </Label>
            )}
            <div className={cn(FF.FormFieldHolder, wide && FF.wide)}>
                <div>
                    {children}

                    {/* Show both custom error messages and react-aria ones */}
                    {errorMessage && <div className={FF.FormFieldError}>{errorMessage}</div>}
                    <FieldError className={FF.FormFieldError} />
                </div>
                <Text className={FF.FormFieldMessage} slot="description">
                    {description}
                </Text>
            </div>
        </>
    );
}
