import { useAtomValue } from "jotai/react";
import { getDefaultStore } from "jotai/vanilla";
import { useLayoutEffect } from "react";
import { colorSchemeAttribute } from "../theme/shared.ts";
import { currentColorSchemeAtom, type ColorSchemeValue } from "./ColorSchemeSwitcher.store.ts";

export function ColorSchemeSwitcher() {
    // Only supports global store
    const currentScheme = useAtomValue(currentColorSchemeAtom, { store: getDefaultStore() });

    useLayoutEffect(() => {
        setColorSchemeAttribute(currentScheme);
    }, [currentScheme]);

    return null;
}

function setColorSchemeAttribute(theme: ColorSchemeValue) {
    const el = document.documentElement;
    if (el.getAttribute(colorSchemeAttribute) === theme) {
        return;
    }
    console.debug("Setting color scheme attribute to: %o", theme);
    el.setAttribute(colorSchemeAttribute, theme);
}
