import * as TC from "@ctrl/tinycolor";
import { clamp } from "@warrenio/utils/clamp";
import { mapObj } from "@warrenio/utils/collections/mapObj";
import { isDeepEqual } from "remeda";
import type { VarGetter } from "../types.ts";
import { colorString } from "./tinyColorUtils.ts";

const dbg = false;

interface Hsla {
    h: number;
    s: number;
    l: number;
    a: number;
}

function getDarkHslColor(color: Hsla): Hsla {
    const { a, s, l } = color;

    /** Color that is not too dark or too light, suitable for both dark and light themes */
    const isMediumSaturatedColor = l > 0.35 && l < 0.7 && s > 0.7;
    if (isMediumSaturatedColor) {
        return color;
    }

    const isDarkSaturatedColor = l > 0.25 && l < 0.4 && s > 0.9;
    if (isDarkSaturatedColor) {
        return {
            ...color,
            // Beef up the brightness a bit
            l: l + 0.05,
        };
    }

    return {
        ...color,
        // De-saturate
        s: s * 0.8,
        // Apply min and max brightness
        l: clamp(1 - l, 0.06, 0.9),
        // Beef up hovers by increasing alpha (otherwise mixing with black makes them too dark)
        a: clamp(a + 0.2, 0, 1),
    };
}

/** Garbage function 🤡🎨 */
export function toDarkModeColor(colorStr: string) {
    if (!colorStr.startsWith("rgb") && !colorStr.startsWith("#")) {
        return colorStr;
    }

    const color = new TC.TinyColor(colorStr);
    const hsl = color.toHsl();
    const newHsl = getDarkHslColor(hsl);
    const result = colorString(new TC.TinyColor(newHsl));
    if (dbg)
        console.log(
            "%s -> %s : %s -> %s",
            color.toRgbString().padEnd(20),
            result.padEnd(20),
            toHslString(hsl).padEnd(28),
            isDeepEqual(hsl, newHsl) ? "(same)" : toHslString(newHsl),
        );
    return result;
}

export function autoDark(color: string, darkColor?: string): VarGetter<string> {
    return (ctx) => (ctx.isDark ? (darkColor ?? toDarkModeColor(color)) : color);
}

export function autoDarkColors<TKey extends string>(
    vars: Record<TKey, string>,
    darkVars?: Partial<Record<TKey, string>>,
): Record<TKey, VarGetter<string>> {
    return mapObj(vars, (k, v) => [k, autoDark(v, darkVars?.[k])]);
}

function toPercent(value: number) {
    return `${(value * 100).toFixed()}%`;
}

function toHslString({ a, h, l, s }: Hsla) {
    const hsl = `${h.toFixed().padEnd(3)}, ${toPercent(s).padEnd(4)}, ${toPercent(l).padEnd(4)}`;
    return a === 1 ? `hsl(${hsl})` : `hsla(${hsl}, ${toPercent(a).padEnd(4)})`;
}
