import { useRef, useState } from "react";

/** Clone of react-aria-components' `useControlledState` hook, but with a work-around for a Safari + React issue */
export function useControlledState<TControlled, TDefault, TChange = TControlled>(
    controlled: TControlled | undefined,
    defaultValue: TDefault,
    onChange?: (value: TChange) => void,
) {
    const [value, setValue] = useState<TChange | TDefault>(defaultValue);
    const isCurrentlyControlled = controlled !== undefined;
    const isControlled = useRef(isCurrentlyControlled);

    if (isCurrentlyControlled !== isControlled.current) {
        console.warn("Component changed between controlled and uncontrolled");
        isControlled.current = isCurrentlyControlled;
    }

    function onChangeValue(newValue: TChange) {
        if (!isControlled.current) {
            // console.debug("useControlledState onChangeValue: %o -> %o", value, newValue);

            // NB: The `onChange` event MUST be called inside the `setValue` callback.

            // Otherwise in Safari, `value` is not immediately updated but a re-render is triggered, which can cause the
            // component to go into an infinite update loop (if it triggers state updates based on the current value).

            setValue((prevValue) => {
                // console.debug("useControlledState setValue: %o -> %o", prevValue, newValue);
                if (prevValue !== newValue) {
                    onChange?.(newValue);
                }
                return newValue;
            });
        } else {
            onChange?.(newValue);
        }
    }

    const currentValue = isCurrentlyControlled ? controlled! : value;
    return [currentValue, onChangeValue] as const;
}
