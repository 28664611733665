export const editableFields = [
    "address_line1",
    "address_line2",
    "city",
    "company_name",
    "company_reg_code",
    "company_vat_number",
    "country",
    "county",
    "customer_id_number",
    "customer_name",
    "customer_phone",
    "email",
    "post_index",
    "referral_code",
    "title",
    "document",
    "document_name",
] as const satisfies string[];
