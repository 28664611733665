export class KeyNotFoundError extends Error {
    name = "KeyNotFoundError";

    constructor(key: unknown, objName: string) {
        super(`Key ${JSON.stringify(key)} is not defined in ${objName}`);
    }
}

export function getOwnProperty<T>(obj: T, prop: keyof T | null | undefined) {
    if (prop == null || obj == null) {
        return undefined;
    }

    return Object.prototype.hasOwnProperty.call(obj, prop) ? obj[prop] : undefined;
}

export function mustGetProperty<T>(obj: T | null | undefined, prop: keyof T, objName = "an object"): T[keyof T] {
    if (obj == null) {
        throw new Error(`${objName} is null or undefined`);
    }

    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
        throw new KeyNotFoundError(prop, objName);
    }
    return obj[prop];
}
