const proxyHandler: ProxyHandler<object> = {
    get(target: object, prop) {
        if (prop in target) {
            const value = (target as Record<typeof prop, unknown>)[prop];
            // Recursively wrap objects
            if (typeof value === "object" && value != null) {
                return unsetPropertyCheckProxy(value);
            }
            return value;
        }
        console.error(`Property ${String(prop)} was expected but was not found on %o`, target);
        return undefined;
    },
};

/** Warns about any object field accesses that are undefined. */
export function unsetPropertyCheckProxy<T extends object>(obj: T): T {
    if (!import.meta.env.DEV) {
        return obj;
    }

    return new Proxy(obj, proxyHandler as ProxyHandler<T>);
}
