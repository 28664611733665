export const themeStyleElementId = "theme-css";

export function updateCss(content: string, elementId: string) {
    let el = document.getElementById(elementId) as HTMLStyleElement | null;
    if (!el) {
        el = document.createElement("style");
        el.id = elementId;
        el.textContent = content;
        document.head.appendChild(el);
    } else {
        el.textContent = content;
    }
}
