import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";

export const mediaQueryMatcherAtom = atomFamily((query: string) => {
    const matcher = window.matchMedia(query);
    const matchesAtom = atom<boolean>(matcher.matches);
    matchesAtom.onMount = (set) => {
        function listener() {
            set(matcher.matches);
        }
        matcher.addEventListener("change", listener);
        return () => matcher.removeEventListener("change", listener);
    };
    return matchesAtom;
});
