import { KeyNotFoundError } from "./getOwnProperty.ts";

/** For URLSearchParams */
interface MapLike<K, V> {
    has(key: K): boolean;
    get(key: K): V | null;
}

export function mustGet<K, V>(map: Map<K, V> | MapLike<K, V>, key: K, mapName = "a Map"): V {
    if (!map.has(key)) {
        throw new KeyNotFoundError(key, mapName);
    }
    return map.get(key)!;
}
