import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { customErrorResponse, errorResponse, formBody, successResponse, tagPaths } from "../util.ts";
import { billing_account_id, created_at_tz, exampleEmail, int, updated_at_tz, user_id } from "./common.ts";

extendZodWithOpenApi(z);

export const Storage = z
    .object({
        user_id,
        name: z.string(),
        size_bytes: int,
        billing_account_id,
        num_objects: int,
        created_at: created_at_tz,
        modified_at: updated_at_tz.optional(),
        owner: z.string().openapi({ example: exampleEmail }),
        is_suspended: z.boolean(),
    })
    .openapi({ ref: "Storage" });

export const StorageKey = z
    .object({
        userId: z.string(),
        accessKey: z.string(),
        secretKey: z.string(),
    })
    .openapi({ ref: "StorageKey" });

export const S3Url = z
    .object({
        url: z.string().url().openapi({ example: "https://s3-test.example.com" }),
    })
    .openapi({ ref: "S3Url" });

export const BucketListResponse = z.array(Storage).openapi({ ref: "BucketListResponse" });

export const ObjectStorageCreateBody = z
    .object({
        name: z.string().regex(/^(?!^xn--|.+-s3alias$)[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/), // https://stackoverflow.com/a/50484916 for this validation regex pattern
        billing_account_id: billing_account_id.optional(),
    })
    .openapi({ ref: "ObjectStorageCreateBody" });

export const ObjectStorageUpdateBody = z
    .object({
        name: Storage.shape.name,
        billing_account_id,
    })
    .openapi({ ref: "ObjectStorageUpdateBody" });

export const AccessKeyDeleteBody = z.object({ access_key: z.string() }).openapi({ ref: "AccessKeyDeleteBody" });

// TODO: Figure out why the error formats from the backend are not properly standardized
// TODO: Figure out if other endpoints also use this random format
const XXX_UnknownError = z.object({ message: z.string() }).openapi({ ref: "UnknownError" });

const BucketCorsBody = z
    .object({
        name: Storage.shape.name,
        allowed_origin: z.string().url(),
    })
    .openapi({ ref: "BucketCorsBody" });

export const storagePaths = tagPaths("storage")({
    "/storage/bucket": {
        get: {
            summary: "Get storage bucket information by name",
            requestBody: formBody(z.object({ name: z.string() })),
            responses: {
                ...successResponse(Storage),
                ...errorResponse("Failed to get storage bucket"),
            },
        },
        put: {
            summary: "Create storage bucket",
            requestBody: formBody(ObjectStorageCreateBody),
            responses: {
                ...successResponse(Storage),
                ...errorResponse("Failed to create storage bucket"),
            },
        },
        patch: {
            summary: "Change storage bucket billing account",
            requestBody: formBody(ObjectStorageUpdateBody),
            responses: {
                ...successResponse(Storage),
                ...errorResponse("Failed to change storage bucket billing account"),
            },
        },
        delete: {
            summary: "Delete storage bucket",
            requestBody: formBody(z.object({ name: z.string() })),
            responses: {
                ...successResponse(Storage),
                ...errorResponse("Failed to delete storage bucket"),
            },
        },
    },
    "/storage/bucket/list": {
        get: {
            summary: "List storage buckets",
            responses: {
                ...successResponse(BucketListResponse),
                ...customErrorResponse(XXX_UnknownError, { message: "IOException" }),
            },
        },
    },
    "/storage/bucket/cors": {
        put: {
            summary: "Set CORS configuration for a bucket",
            requestBody: formBody(BucketCorsBody),
            responses: {
                "200": {
                    description: "Current S3 UI CORS configuration XML",
                    content: { "application/xml": {} },
                },
            },
        },
    },
    "/storage/user": {
        get: {
            //TODO: kas see yldse tuleb
            summary: "Get S3 user info (including access and secret keys)",
            description: "User and keys will be generated, if they do not exist already.",
            responses: {
                ...successResponse(undefined!),
                ...errorResponse("Failed to get S3 user info"),
            },
        },
    },
    "/storage/user/keys": {
        get: {
            summary: "List S3 keys",
            responses: { ...successResponse(z.array(StorageKey)) },
        },
        post: {
            summary: "Generate S3 access key",
            responses: { ...successResponse(z.array(StorageKey)) },
        },
        delete: {
            summary: "Delete S3 access key",
            requestBody: formBody(AccessKeyDeleteBody),
            responses: { ...successResponse(z.unknown()) },
        },
    },
    "/storage/api/s3": {
        get: {
            summary: "Get S3 URL",
            responses: {
                ...successResponse(S3Url),
            },
        },
    },
});
