// Importing for docstring-only usage is not detected in ESLint, so we have to disable it.
/* eslint-disable @typescript-eslint/no-unused-vars */
import NiceModal, { type NiceModalHandler } from "@ebay/nice-modal-react";
import type { ModalOverlayProps } from "react-aria-components";

/**
 * Convert a {@link NiceModal} into props for a {@link DialogOverlay} component.
 * @example <DialogOverlay {...niceOverlayProps(modal)}>
 */
export function niceOverlayProps(modal: NiceModalHandler) {
    return {
        isOpen: modal.visible,
        onOpenChange: (b) => void (b ? modal.show() : modal.remove()),
    } satisfies Partial<ModalOverlayProps>;
}
