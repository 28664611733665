import { atom, type PrimitiveAtom } from "jotai/vanilla";
import { stable } from "./stable.ts";

/**
 * @__NO_SIDE_EFFECTS__
 */
export function stableAtom<T>(id: string, init: () => T): PrimitiveAtom<T> {
    const [value] = stable(id, () => atom(init()));
    value.debugLabel ??= id;
    return value;
}
