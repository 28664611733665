import { isTestEnvironment } from "./utils/environment.ts";

export const reactStrictMode = false;

/** Delay for GET & other idempotent requests (milliseconds) */
export const requestDelay = 0;
/** Run a single request at a time (useful with a delay) */
export const requestsInSerial = false;
/** Delay for POST, PUT, DELETE etc. requests */
export const mutationRequestDelay = isTestEnvironment ? 0 : 1000;
export const importDelay = 0;
