/**
 * Create an object from a collection of {@link items}.
 * The `[key, value]` pairs are provided by {@link entryFn}.
 *
 * When multiple items have the same key, the last one will be used.
 */
export function objFromEntries<T, RK extends string | number | symbol, RV>(
    items: Iterable<T>,
    entryFn: (v: T) => [RK, RV],
) {
    const result = {} as { [k in RK]: RV };
    for (const entry of items) {
        const [rk, rv] = entryFn(entry);
        result[rk] = rv;
    }
    return result;
}

/**
 * Create an object from a set of keys.
 *
 * @returns An object with the keys from {@link keys} and the values provided by {@link valueFn}.
 */
export function objectFromKeys<T, TKey extends string>(keys: Iterable<TKey>, valueFn: (key: TKey) => T) {
    const result = {} as Record<TKey, T>;
    for (const key of keys) {
        result[key] = valueFn(key);
    }
    return result;
}
