import { autoDarkColors } from "../../modules/theme/color/darkModeColor";
import type { ThemeProps, ThemeVarGetters, ThemeVariant } from "../../modules/theme/types";

import favicon from "./favicon.svg?url";
import isoImage27001 from "./iso-27001.svg?url";
import isoImage9001 from "./iso-logo-idch.svg?url";
import loginImage from "./login-bg.svg";
import loginIntroBg from "./login-body.png?url";
import logoDark from "./logo-dark.svg?url";
import loginLogo from "./logo.svg";
import logo from "./logo.svg?url";

export function makeTheme(variant: ThemeVariant) {
    const { isDark } = variant;

    const baseColors: ThemeVarGetters = autoDarkColors({
        "color-primary": "#3299FE",
        "color-secondary": "#e5e5e5",
    });

    return {
        ...baseColors,

        "logo-image": isDark ? `url("${logoDark}")` : `url("${logo}")`,

        "header-logo-width": "98px",
        "header-logo-height": "17px",

        "sidebar-item-icon-color": "#EC72BB",

        "sidenav-bg-color": isDark ? "#2A2A2A" : "#232E6C",
        "sidenav-item-border": "1px solid transparent",
        "sidenav-item-icon-color": isDark ? "var(--color-primary)" : "#EC72BB",
        "sidenav-item-bg-color": "transparent",
        "sidenav-item-text-color": "#FFFFFF",
        "sidenav-item-before-width": "1px",
        "sidenav-item-hover-bg-color": isDark ? "#2424247F" : "#FFFFFF0C",
        "sidenav-item-hover-icon-color": isDark ? "var(--color-primary)" : "#EC72BB",
        "sidenav-item-hover-text-color": "#FFFFFF",
        "sidenav-item-active-bg-color": isDark ? "#242424" : "#FFFFFF19",
        "sidenav-item-active-icon-color": isDark ? "var(--color-primary)" : "#EC72BB",
        "sidenav-item-active-text-color": "#FFFFFF",
        "sidenav-item-active-before-bg-color": "var(--color-primary)",
        "sidenav-item-border-radius": "0",

        //#region Login
        "login-body-bg-color": "var(--color-text)",
        "login-body-bg-image": `url("${loginIntroBg}")`,
        "login-body-bg-size": "cover",
        "login-body-text-color": "var(--color-white)",
        "login-justify-content": "space-between",
        "login-align-items": "stretch",

        "login-intro-display": "flex",
        "login-intro-bg-image": "",
        "login-intro-bg-size": "cover",
        "login-intro-bg-position": "50% 50%",
        "login-intro-text-align": "center",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "center",
        "login-intro-align-items": "center",
        "login-intro-padding": "80px",
        "login-title-font-size": "36px",
        "login-title-text-color": "",
        "login-title-font-weight": 700,
        "login-title-line-height": 1,
        "login-title-max-width": "550px",
        "login-title-padding": "0 0 32px 0",
        "login-subtitle-font-size": "21px",
        "login-subtitle-max-width": "",

        "login-form-title-font-size": "30px",
        "login-form-bg-color": "var(--color-white)",
        "login-form-text-color": "var(--color-text)",
        "login-form-border-left": "",
        "login-form-input-border-color": "#dbe2e9",
        "login-form-button-border-radius": "var(--button-border-radius)",
        "login-form-or-text-color": "var(--color-text)",
        "login-form-or-border-color": "var(--color-white)",
        "login-form-google-text-color": "var(--color-text)",
        "login-form-google-bg-color": "var(--color-white)",
        "login-form-more-text-color": "var(--color-primary)",
        "login-form-more-bg-color": "transparent",
        "login-form-more-border-color": "var(--color-primary)",
        //#endregion
    } satisfies ThemeVarGetters;
}

export const props: ThemeProps = {
    favicon,
    loginLogoImg: {
        src: loginLogo,
        style: {
            maxWidth: "200px",
        },
    },
    loginIntroImg: {
        src: loginImage,
        style: {
            maxWidth: "580px",
            width: "100%",
            order: 2,
        },
    },
    loginGoogleButton: {
        icon: "",
        style: {
            justifyContent: "center",
            boxShadow: "0px 16px 32px rgba(0, 7, 46, 0.05)",
            backgroundPosition: "16px center",
            backgroundRepeat: "no-repeat",
            backgroundImage:
                "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik01LjI2NTk5IDkuNzY1QzUuNzMzODcgOC4zNDk0NSA2LjYzNjg1IDcuMTE3ODEgNy44NDYxMSA2LjI0NThDOS4wNTUzNiA1LjM3Mzc4IDEwLjUwOTEgNC45MDU5NCAxMiA0LjkwOUMxMy42OSA0LjkwOSAxNS4yMTggNS41MDkgMTYuNDE4IDYuNDkxTDE5LjkxIDNDMTcuNzgyIDEuMTQ1IDE1LjA1NSAwIDEyIDBDNy4yNjk5OSAwIDMuMTk3OTkgMi42OTggMS4yMzk5OSA2LjY1TDUuMjY1OTkgOS43NjVaIiBmaWxsPSIjRUE0MzM1Ii8+CjxwYXRoIGQ9Ik0xNi4wNDAxIDE4LjAxM0MxNC45NTAxIDE4LjcxNiAxMy41NjYxIDE5LjA5MSAxMi4wMDAxIDE5LjA5MUMxMC41MTUxIDE5LjA5NCA5LjA2Njg2IDE4LjYyOTkgNy44NjAzIDE3Ljc2NDRDNi42NTM3MyAxNi44OTg4IDUuNzUgMTUuNjc1NiA1LjI3NzA2IDE0LjI2OEwxLjIzNzA2IDE3LjMzNUMyLjIyODMxIDE5LjM0MTMgMy43NjIzMyAyMS4wMjk0IDUuNjY0ODggMjIuMjA3NUM3LjU2NzQ0IDIzLjM4NTcgOS43NjIyNyAyNC4wMDY3IDEyLjAwMDEgMjRDMTQuOTMzMSAyNCAxNy43MzUxIDIyLjk1NyAxOS44MzQxIDIxTDE2LjA0MTEgMTguMDEzSDE2LjA0MDFaIiBmaWxsPSIjMzRBODUzIi8+CjxwYXRoIGQ9Ik0xOS44MzQgMjFDMjIuMDI5IDE4Ljk1MiAyMy40NTQgMTUuOTA0IDIzLjQ1NCAxMkMyMy40NTQgMTEuMjkgMjMuMzQ1IDEwLjUyNyAyMy4xODIgOS44MThIMTJWMTQuNDU1SDE4LjQzNkMxOC4xMTkgMTYuMDE0IDE3LjI2NiAxNy4yMjEgMTYuMDQxIDE4LjAxM0wxOS44MzQgMjFaIiBmaWxsPSIjNEE5MEUyIi8+CjxwYXRoIGQ9Ik01LjI3Njk5IDE0LjI2OEM1LjAzMjM1IDEzLjUzNjkgNC45MDgwNiAxMi43NzA5IDQuOTA4OTkgMTJDNC45MDg5OSAxMS4yMTggNS4wMzM5OSAxMC40NjcgNS4yNjU5OSA5Ljc2NUwxLjIzOTk5IDYuNjVDMC40MTYzNzIgOC4zMTI2OSAtMC4wMDgyMDIwMSAxMC4xNDQ1IC01LjYxNTc3ZS0wNiAxMkMtNS42MTU3N2UtMDYgMTMuOTIgMC40NDQ5OTQgMTUuNzMgMS4yMzY5OSAxNy4zMzVMNS4yNzY5OSAxNC4yNjhaIiBmaWxsPSIjRkJCQzA1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')",
        },
    },
    isoCertificates: [
        {
            image: isoImage9001,
            url: "https://www.bsigroup.com/en-ID/validate-bsi-issued-certificates/client-directory-certificate/FS%20773988",
        },
        {
            image: isoImage27001,
            url: "https://www.bsigroup.com/en-ID/validate-bsi-issued-certificates/client-directory-certificate/IS%20792648",
        },
    ],
};
