import C from "./Todo.module.css";

import type { ComponentProps } from "react";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { mcn, type BaseProps } from "../utils/baseProps";

interface TodoProps extends ComponentProps<"span">, BaseProps {
    what?: string;
    /** Icon only */
    small?: boolean;
}

/** Inline "Todo" component */
export function TODO({ children, what, small, ...props }: TodoProps) {
    const description = children ?? what;
    return (
        <span {...mcn(`text-muted select-none ${C.Todo}`, props)}>
            <TodoInlineIcon />
            {!small ? <>TODO{description && <>: {description}</>}</> : description}
        </span>
    );
}

export function TodoInlineIcon(props: BaseProps) {
    return <MaskIcon title="TODO" {...mcn("jp-construction-icon size-1.1em mb-[-0.25em] mr-0.3em", props)} />;
}

export function TodoButtonIcon(props: BaseProps) {
    return <MaskIcon {...mcn("jp-construction-icon size-1.1em", props)} />;
}
