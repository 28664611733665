import type { Location } from "@warrenio/api-spec/spec.oats.gen";

export type ExampleLoc = typeof loc1Tll | typeof loc2Jhv | typeof loc3Jkt | typeof loc4Trtu;

export const loc1Tll = "tll";
export const loc2Jhv = "jhv";
export const loc3Jkt = "jkt01";
export const loc4Trtu = "trtu";

const tll: Location = {
    display_name: "Tallinn",
    is_default: false,
    is_preferred: true,
    description: "New DC in Tallinn",
    order_nr: 2,
    country_code: "est",
    slug: loc1Tll,
};

const jhv: Location = {
    display_name: "Jõhvi",
    is_preferred: false,
    is_default: true,
    is_published: true,
    description: "The original",
    country_code: "est",
    order_nr: 1,
    create_resource_disabled: 0,
    mikrotik: 1,
    slug: loc2Jhv,
};

const pilvLocationList: Location[] = [
    jhv,
    tll,
    {
        display_name: "Tartu",
        is_default: false,
        is_preferred: false,
        description: "DC for testing",
        order_nr: 3,
        country_code: "est",
        slug: loc4Trtu,
    },
];

/** Only use a subset of the available mock locations to reduce query spam in development */
export const mockLocationConfig: Location[] = [tll, jhv];

export const multiLocationList: Location[] = [
    ...pilvLocationList,
    {
        display_name: "SouthJKT-a",
        is_preferred: false,
        is_default: false,
        description: "SouthJKT-a (jkt01)",
        country_code: "idn",
        order_nr: 4,
        create_resource_disabled: false,
        slug: loc3Jkt,
    },
    /*
    {
        display_name: "Singapore",
        is_preferred: false,
        is_default: false,
        description: "Singapore (sgp01)",
        country_code: "sgp",
        order_nr: 3,
        create_resource_disabled: false,
        slug: "sgp01",
    },
    */
];

export const singleLocationList: Location[] = [multiLocationList.find((l) => l.slug === loc1Tll)!];
