import { z } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { jsonBody, successResponse } from "../util.ts";
import { int, simpleSuccessResponse } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

function makeResourcePrice(type: string, schema: Record<string, z.ZodTypeAny>) {
    return z
        .object({
            resource_type: z.literal(type),
            resource_prices: z.array(
                z.object({
                    price_per_unit: z.number(),
                    price_per_unit_month: z.number().optional(),
                    ...schema,
                    description: z.string(),
                }),
            ),
        })
        .openapi({ ref: `Price_${type}`, title: `${type}` });
}

const num_cpus_from = int;
const gigabytes_from = z.number();

export namespace Price {
    export const CPU = makeResourcePrice("CPU", {
        num_cpus_from,
    });

    export const RAM = makeResourcePrice("RAM", {
        gigabytes_from,
    });

    export const STORAGE = makeResourcePrice("STORAGE", {
        gigabytes_from,
    });

    export const BLOCK_STORAGE = makeResourcePrice("BLOCK_STORAGE", {
        gigabytes_from,
    });

    export const BLOCK_STORAGE_BACKUP = makeResourcePrice("BLOCK_STORAGE_BACKUP", {
        gigabytes_from,
    });

    export const BLOCK_STORAGE_SNAPSHOT = makeResourcePrice("BLOCK_STORAGE_SNAPSHOT", {
        gigabytes_from,
    });

    export const WINDOWS_LICENSE = makeResourcePrice("WINDOWS_LICENSE", {});

    export const OBJECT_STORAGE = makeResourcePrice("OBJECT_STORAGE", {});

    export const UNASSIGNED_FLOATING_IP = makeResourcePrice("UNASSIGNED_FLOATING_IP", {});

    export const ASSIGNED_FLOATING_IP = makeResourcePrice("ASSIGNED_FLOATING_IP", {});

    export const LOAD_BALANCER = makeResourcePrice("LOAD_BALANCER", {});
}

const PriceFieldsBase = z
    .object({
        price_per_unit: z.number(),
        price_per_unit_month: z.number().optional(),
        description: z.string(),
        num_cpus_from: num_cpus_from.optional(),
        gigabytes_from: gigabytes_from.optional(),
    })
    .openapi({ ref: "PriceFieldsBase" });

const ResourceTypePricesBase = z
    .object({
        resource_type: z.string(),
        resource_prices: z.array(PriceFieldsBase),
    })
    .openapi({ ref: "ResourceTypePricesBase" });

export const ResourceTypePrices = ResourceTypePricesBase.and(
    z.discriminatedUnion("resource_type", [
        Price.CPU,
        Price.RAM,
        Price.STORAGE,
        Price.BLOCK_STORAGE,
        Price.BLOCK_STORAGE_BACKUP,
        Price.BLOCK_STORAGE_SNAPSHOT,
        Price.WINDOWS_LICENSE,
        Price.OBJECT_STORAGE,
        Price.UNASSIGNED_FLOATING_IP,
        Price.ASSIGNED_FLOATING_IP,
        Price.LOAD_BALANCER,
    ]),
).openapi({
    ref: "ResourceTypePrices",
});

export const ScheduledPricingBody = z
    .record(z.record(z.array(ResourceTypePrices)).openapi({ "x-additionalPropertiesName": "Pool" }))
    .openapi({ ref: "ScheduledPricingBody", "x-additionalPropertiesName": "Currency" });

export const PricingList = z
    .record(z.record(z.array(ResourceTypePrices)).openapi({ "x-additionalPropertiesName": "Pool" }))
    .openapi({ ref: "PricingList", "x-additionalPropertiesName": "Currency" });

export const PricingHistory = z.record(PricingList).openapi({ ref: "PricingHistory" });

export const pricingPaths: ZodOpenApiPathsObject = {
    "/pricing/list": {
        get: {
            summary: "Get pricing history",
            tags: ["pricing"],
            responses: {
                ...successResponse(PricingHistory),
            },
        },
    },
    "/pricing/list/{id}": {
        put: {
            description: "Add scheduled prices",
            parameters: [params.id],
            requestBody: jsonBody(ScheduledPricingBody),
            responses: { ...simpleSuccessResponse },
        },
        delete: {
            description: "Delete scheduled prices",
            parameters: [params.id],
            responses: { ...simpleSuccessResponse },
        },
    },
    "/pricing/list/current": {
        get: {
            summary: "Get pricing information",
            tags: ["pricing"],
            responses: {
                ...successResponse(PricingList),
            },
        },
    },
};
