import { addDevVars } from "../dev/initDev.tsx";

const registry = new Map<string, unknown>();

/**
 * Construct a value only once using {@link fn}.
 *
 * Useful to make sure globals persist across hot reloads. (eg. atoms)
 *
 * {@link id} should be globally unique.
 */
export function stable<T>(id: string, fn: () => T): [T, hasReloaded: boolean] {
    if (registry.has(id)) {
        const value = registry.get(id) as T;
        console.debug("Reusing stable value for %s: %o", id, value);
        return [value, true];
    }

    const value = fn();
    registry.set(id, value);
    return [value, false];
}

addDevVars({ registry });

//#region Hot reload
export function __setRegistry(newRegistry: typeof registry) {
    for (const [key, value] of newRegistry) {
        registry.set(key, value);
    }
}

import.meta.hot?.accept((mod: unknown) => {
    const newModule = mod as typeof import("./stable.ts");
    newModule.__setRegistry(registry);
});
//#endregion
