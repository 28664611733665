import { mapObj } from "@warrenio/utils/collections/mapObj";
import { alpha, darken, hoverMix, makeGreys, whiten } from "../modules/theme/color/colorFunctions.ts";
import { autoDarkColors } from "../modules/theme/color/darkModeColor.ts";
import type { AllThemeVarsGetters, ThemeProps, ThemeVariant } from "../modules/theme/types.ts";

export function makeTheme(variant: ThemeVariant) {
    const baseColors = autoDarkColors(
        {
            "color-primary": "#ff00ff",
            "color-secondary": "#00ffff",

            "color-text": "#091b25",
            "color-muted": "#989898",
            "color-error": "#d30021",
            "color-success": "#009345",
            "color-info": "#00ccff",
            "color-warning": "#F57C00",

            "color-white": "#ffffff",
            "color-black": "#000000",
            "color-bg": "#ffffff",
        },
        {
            "color-white": "#191a1b",
            "color-bg": "#363636",
        },
    );

    const variantColors = { ...baseColors, ...makeGreys() };

    /** `$color-hover` versions of {@link variantColors} */
    const hoverColors = {
        ...mapObj(variantColors, (k) => [`${k}-light` as const, () => hoverMix(k)]),
        ...mapObj(variantColors, (k) => [`${k}-dark` as const, () => darken(k, 0.75)]),
        ...mapObj(variantColors, (k) => [`${k}-bright` as const, () => hoverMix(k, 0.85)]),
        ...mapObj(variantColors, (k) => [`${k}-hover-alpha` as const, () => alpha(k, 0.08)]),
        ...mapObj(variantColors, (k) => [`${k}-dark-alpha` as const, () => alpha(darken(k, 0.75), 0.16)]),
    };

    const shadowColor = variant.isDark ? "rgb(0,0,0)" : "color-black";

    return {
        // TODO: should all these colors be accessible as variables? should not use `grey-N` colors directly, probably
        ...variantColors,
        ...hoverColors,

        "color-outline": () => whiten("color-primary", 0.5),
        "color-loading": () => alpha("color-grey-1", 0.75),

        //#region Fonts

        "font-size-small": "11px",
        "font-size-default": "13px",
        "font-size-subtitle": "16px",
        "font-size-title": "18px",
        "font-size-heading": "24px",
        "font-size-large": "32px",
        lh: "1.3", // line-height
        "body-font-family":
            "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
        "code-font-family": "Menlo, Consolas, 'DejaVu Sans Mono', monospace",

        //#endregion

        "form-max-width": "700px",
        "border-radius-default": "4px",
        "border-radius-large": "8px",

        "button-primary-bg": "var(--color-primary)",
        "button-border-radius": "4px",
        "button-new-bg": "var(--color-primary)",

        "icon-radio-color": variant.isDark ? "#fff" : "var(--color-muted)",
        "icon-radio-hover-color": variant.isDark ? "#fff" : "var(--color-primary)",
        "icon-radio-primary-bg": "var(--color-primary)",
        "icon-radio-border-radius": "var(--border-radius-default)",

        "input-height": "36px",
        "input-bg-color": "var(--color-white)",
        "input-text-size": "13px",
        "input-text-color": "var(--color-text)",
        "input-border-color": "var(--color-white) var(--color-grey-3) var(--color-grey-3) var(--color-white)",
        "input-border-style": "solid",
        "input-border-width": "1px",
        "input-border-radius": "4px",

        "menu-box-shadow": () => `${alpha(shadowColor, 0.2)} 0 2px 4px -1px,
            ${alpha(shadowColor, 0.15)} 0 4px 5px 0,
            ${alpha(shadowColor, 0.12)} 0 1px 10px 0;`,

        "header-height": "35px",
        "header-text-size": "var(--font-size-default)",
        "header-bg-color": variant.isDark ? "var(--color-grey-2)" : "var(--color-white)",
        "header-border-bottom": "var(--color-grey-4) solid 1px",

        "header-separator-height": "var(--header-logo-height)",
        "header-separator-bg-color": "var(--color-grey-3)",

        "header-avatar-width": "14px",
        "header-avatar-bg-color": () => whiten("color-success", 0.1),
        "header-avatar-text-color": "var(--color-success)",
        "header-avatar-text-size": "7px",
        "header-avatar-border-radius": "50%",

        "sidenav-width": "80px",
        "sidenav-bg-color": variant.isDark ? "var(--color-grey-2)" : "var(--color-white)",
        "sidenav-border-color": "var(--color-grey-4)",
        "sidenav-border-style": "solid",
        "sidenav-border-width": "0 1px 0 0",

        "sidenav-item-padding": "12px 0 14px 0",
        "sidenav-item-bg-color": "var(--sidenav-bg-color)",
        "sidenav-item-icon-width": "24px",
        "sidenav-item-icon-color": "var(--color-primary)",
        "sidenav-item-text-size": "10px",
        "sidenav-item-text-padding": "8px",
        "sidenav-item-text-color": "var(--color-text)",
        "sidenav-item-text-transform": "uppercase",
        "sidenav-item-box-shadow": "none",
        "sidenav-item-border": variant.isDark ? "1px solid transparent" : "1px solid var(--sidenav-bg-color)", // shared
        "sidenav-item-border-radius": "var(--border-radius-default)", // shared
        "sidenav-item-before-display": "block",
        "sidenav-item-before-width": "4px",
        "sidenav-item-before-bg-color": "transparent",

        "sidenav-item-hover-bg-color": () => hoverMix("color-primary"),
        "sidenav-item-hover-icon-color": "var(--color-primary)",
        "sidenav-item-hover-text-color": "var(--color-text)",
        "sidenav-item-hover-box-shadow": "none",
        "sidenav-item-hover-before-display": "none",
        "sidenav-item-hover-before-bg-color": "transparent",

        "sidenav-item-active-bg-color": () => hoverMix("color-primary", 0.1),
        "sidenav-item-active-icon-color": "var(--color-primary)",
        "sidenav-item-active-text-color": "var(--color-text)",
        "sidenav-item-active-box-shadow": "none", //"inset 4px 0 0 0 var(--color-primary)",
        "sidenav-item-active-before-display": "block",
        "sidenav-item-active-before-bg-color": "var(--color-primary)",

        "sidebar-width": "320px",
        "sidebar-item-icon-color": "var(--color-primary)",
        "sidebar-header-button-color": "var(--color-primary)",
        "sidebar-item-border": variant.isDark ? "1px solid transparent" : "1px solid var(--color-white)", // shared
        "sidebar-item-border-radius": "var(--border-radius-default)", // shared

        //#region Login
        "login-body-bg-color": "var(--color-text)",
        "login-body-bg-size": "cover",
        "login-body-text-color": "var(--color-white)",
        "login-justify-content": "space-between",
        "login-align-items": "stretch",

        "login-intro-display": "flex",
        "login-intro-bg-size": "cover",
        "login-intro-bg-position": "50% 50%",
        "login-intro-text-align": "center",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "center",
        "login-intro-align-items": "center",

        "login-title-font-size": "36px",
        "login-title-text-color": "",
        "login-title-font-weight": 300,
        "login-title-line-height": 1,
        "login-title-padding": "20px 0 6px 0",

        "login-subtitle-font-size": "21px",
        "login-subtitle-max-width": "",

        "login-form-title-font-size": "30px",

        "login-form-bg-color": "var(--color-text)",
        "login-form-text-color": "var(--color-white)",
        "login-form-border-left": "",

        "login-form-input-border-color": "",
        "login-form-input-bg-color": "",

        "login-form-button-border-radius": "var(--button-border-radius)",
        "login-form-button-border-color": "var(--color-primary)",
        "login-form-button-bg-color": "var(--color-primary)",

        "login-form-or-text-color": "var(--color-white)",

        "login-form-more-text-color": "var(--color-white)",
        "login-form-more-bg-color": "transparent",
        "login-form-more-border-color": "var(--color-primary)",

        "login-form-google-text-color": "var(--color-white)",
        //#endregion
        // NB: default theme must declare all theme variables (since it is used as a fallback)
    } satisfies AllThemeVarsGetters;
}

export const props: Partial<ThemeProps> = {};
