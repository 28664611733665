const MOBILE_SCREEN_WIDTH = 700;

export const isMobileDevice = getIsMobileDevice();

function getIsMobileDevice(): boolean {
    /** Pretend the screen is the size of the browser window in development */
    const screenWidth = import.meta.env.DEV ? window.innerWidth : window.screen.width;
    console.debug("screenWidth: %o", screenWidth);
    return screenWidth <= MOBILE_SCREEN_WIDTH;
}
