import { useRef } from "react";

const EMPTY = Symbol.for("useOnce/EMPTY");

/** Run a function once and return its result. */
export function useOnce<T>(fn: () => T): T {
    const hasRun = useRef<T | typeof EMPTY>(EMPTY);
    const { current } = hasRun;
    if (current !== EMPTY) {
        return current;
    }

    return (hasRun.current = fn());
}
