import FF from "./FormField.module.css";

import type { TextFieldProps } from "react-aria-components";
import { TextField } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { FormFieldChildren, type FormFieldChildrenProps } from "./FormField.tsx";
import { standardFieldAttributes, type StandardFieldProps } from "./standardFieldAttributes.tsx";

export interface WTextFieldProps
    extends Omit<TextFieldProps, "children" | "className">,
        StandardFieldProps,
        Pick<FormFieldChildrenProps, "wide" | "description" | "children" | "label" | "errorMessage"> {
    className?: string;
}

export function WTextField({ className, children, wide, description, label, errorMessage, ...props }: WTextFieldProps) {
    return (
        <TextField className={cn(FF.FormField, className)} {...props} {...standardFieldAttributes(props)}>
            <FormFieldChildren wide={wide} description={description} label={label} errorMessage={errorMessage}>
                {children}
            </FormFieldChildren>
        </TextField>
    );
}
