import type { MainConfig } from "@warrenio/api-spec/spec.oats.gen";
import { atom } from "jotai/vanilla";
import { getResponseData } from "./modules/api/apiClient.ts";
import { unauthApiClientAtom } from "./modules/api/createApiClient.ts";
import { atomAsyncWithCache } from "./utils/jotai/atomAsyncWithCache.ts";
import { jsonLocalStorage } from "./utils/jotai/atomStorage.ts";

const uncachedConfigFetchAtom = atom(async (get, { signal }): Promise<MainConfig> => {
    const apiClient = get(unauthApiClientAtom);
    return getResponseData(await apiClient.GET("/config/ui/config", { signal }));
});

export const [
    configWaitFetchAtom,
    /** Last fetched config or `undefined` if not fetched yet */
    configFetchedAtom,
] = atomAsyncWithCache(uncachedConfigFetchAtom, "cachedConfig", jsonLocalStorage);
