import { z } from "zod";
import { zfd } from "zod-form-data";
import { extendZodWithOpenApi } from "zod-openapi";
import { successResponse } from "../util.ts";

extendZodWithOpenApi(z);

//#region Basic types

export const int = z.number().int();
export const uuid = z.string().uuid();
export const not_a_uuid = z.string().describe("Not a UUID, a generic string identifier");
export const json_string = z
    .string()
    .describe("String containing JSON")
    .openapi({ example: '{"key": "value"}', contentMediaType: "application/json" });
export const int_or_string = z.union([int, z.string()]);

export const ipv4 = z.string().ip({ version: "v4" }).describe("IPv4 Address").openapi({ example: "10.1.2.3" });
export const ipv6 = z
    .string()
    .ip({ version: "v6" })
    .describe("IPv6 Address")
    .openapi({ example: "2001:0db8:85a3::8a2e:0370:7334" });

export const mac = z
    .string()
    .regex(/^[0-9a-f]{2}(:[0-9a-f]{2}){5}$/)
    .describe("MAC address")
    .openapi({ example: "00:00:5e:00:53:01" });

export const broken_boolean = z.union([z.literal(0), z.literal(1), z.boolean()]);

//#endregion

//#region Common parameters (for use in query strings, request bodies, etc.)

export const optional_uuid_param = zfd.text(uuid.optional());

//#endregion

//#region ID fields
export const location = z.string().describe("Data center code").openapi({ example: "tll" });
export const billing_account_id = int.describe("Billing account ID").openapi({ ref: "BillingAccountId", example: 123 });
export const user_id = int.describe("User ID").openapi({ ref: "user_id", example: 345 });
export const vm_uuid = uuid.describe("Virtual Machine ID");

//#endregion

//#region Date and time types
export const datetime = z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
    .openapi({ example: "2024-01-01 13:45:56" });

export const datetime_tz = z
    .string()
    .datetime({ offset: true })
    .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{4}$/)
    .describe("Date and time, ISO with offset")
    .openapi({ format: "date-time", example: "2024-01-01T13:45:56.789+0000" });

export const datetime_z = z
    .string()
    .datetime()
    .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)
    .describe("Date and time, ISO")
    .openapi({ ref: "datetime_z", example: "2023-01-12T12:34:56Z" });

export const datetime_usec = z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}$/)
    .describe("Date and time, ISO with microseconds")
    .openapi({ ref: "datetime_usec", example: "2024-01-01 13:45:56.123456" });

export const datetime_epoch_s = z
    .number()
    .int()
    .positive()
    .describe("Date and time as seconds since Unix epoch")
    .openapi({ ref: "datetime_epoch_s", example: 1679595484 });

//#endregion

//#region Date and time fields
export const accepted_at = datetime
    .describe("Created at (UTC timestamp)")
    .openapi({ ref: "accepted_at", example: "2024-01-01 13:45:56" });
export const created_at = datetime
    .describe("Created at (UTC timestamp)")
    .openapi({ ref: "created_at", example: "2024-01-01 13:45:56" });

export const updated_at = datetime
    .describe("Updated at (UTC timestamp)")
    .openapi({ ref: "updated_at", example: "2024-02-02 13:45:56" });

export const deleted_at = datetime
    .optional()
    .describe("Updated at (UTC timestamp)")
    .openapi({ ref: "deleted_at", example: "2024-02-02 13:45:56" });

export const created_at_tz = datetime_tz.describe("Created at").openapi({ ref: "created_at_tz" });
export const updated_at_tz = datetime_tz.describe("Updated at").openapi({ ref: "updated_at_tz" });
//#endregion

export const simpleSuccessResponse = successResponse(z.object({ success: z.boolean() }));

export const exampleEmail = "user@example.com";
