export async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand("copy", true, text);
    }
}

export async function copyElementToClipboard(element: HTMLElement, text?: string) {
    if ("clipboard" in navigator) {
        // Copy rich HTML format to clipboard
        const html = element.outerHTML;
        text ??= element.innerText;
        const clipboardItems = [
            new ClipboardItem({
                "text/plain": new Blob([text], { type: "text/plain" }),
                "text/html": new Blob([html], { type: "text/html" }),
            }),
        ];
        return await navigator.clipboard.write(clipboardItems);
    } else if (text != null) {
        return await copyTextToClipboard(text);
    } else {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection?.removeAllRanges();
        selection?.addRange(range);
        document.execCommand("copy", true);
        selection?.removeAllRanges();
    }
}
