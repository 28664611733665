import { useAtom } from "jotai/react";
import { WSimpleSelect } from "../../../components/dev/WSimpleSelect.tsx";
import { apiPrefixAtom, canSelectApiPrefix } from "../../api/createApiClient.ts";

// TODO(DEV): Share with Vite config
const apiPrefixes = [
    ["/v1", "test.pilw.io"],
    ["/v1-idch", "IDCloudHost"],
];

export function ApiPrefixSelect() {
    if (!canSelectApiPrefix) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [prefix, setPrefix] = useAtom(apiPrefixAtom);

    const items = apiPrefixes.map(([value, label]) => ({ value, label }));

    return (
        <WSimpleSelect
            placeholder="API prefix"
            value={prefix}
            items={items}
            onChange={(value) => {
                setPrefix(value);
            }}
        />
    );
}
