import { MessageStream } from "../utils/messageStream.ts";
import { addDevVars } from "./initDev.tsx";

let id = 1;

export interface ErrorMessage {
    id: number;
    type: "warning" | "error";
    message: string;
    args: unknown[];
    createdAt: number;
}

export const showErrorsStream = new MessageStream<ErrorMessage>();

/** Log an error to the console and display it. */
export function showError(message: string, ...args: unknown[]) {
    console.error(message, ...args);
    showErrorsStream.publish({ type: "error", message, args, id: id++, createdAt: Date.now() });
}

/** Log a warning to the console and display it. */
export function showWarn(message: string, ...args: unknown[]) {
    console.warn(message, ...args);
    showErrorsStream.publish({ type: "warning", message, args, id: id++, createdAt: Date.now() });
}

addDevVars({ showErrorsStream, showError, showWarn });
