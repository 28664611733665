/**
 * Sleep for {@link ms} milliseconds.
 *
 * When the sleep is aborted, the promise will be rejected with the reason of the abort signal.
 *
 * @param ms - Time to sleep in milliseconds.
 * @param abortSignal - Abort signal to cancel the sleep.
 */
export default function sleep(ms: number, abortSignal?: AbortSignal): Promise<void> {
    return new Promise((resolve, reject) => {
        if (abortSignal) {
            const signal = abortSignal;

            function onTimer() {
                signal.removeEventListener("abort", onAbort);
                resolve();
            }

            function onAbort() {
                clearTimeout(timeout);
                reject(signal.reason);
            }

            if (abortSignal.aborted) {
                reject(abortSignal.reason);
                return;
            }

            const timeout = setTimeout(onTimer, ms);
            signal.addEventListener("abort", onAbort);
        } else {
            setTimeout(() => resolve(), ms);
        }
    });
}
