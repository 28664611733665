import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { atom } from "jotai/vanilla";
import type { RouterLinkProps } from "../../components/Action.tsx";
import type { AnyResource } from "../../components/ResourceLink.tsx";
import { configAtom } from "../../config.ts";
import { delegationLink, tokenLink } from "../access/links.ts";
import { billingAccountLink } from "../billing/links.ts";
import { vmLink } from "../compute/links.ts";
import { leaseLink } from "../metal/links.ts";
import { ipAddressLink, loadBalancerLink, vpcLink } from "../network/links.ts";
import { serviceLink } from "../services/links.ts";
import { objectStorageLink } from "../storage/links.ts";
import { profileLink } from "./resourceTypeLinks.ts";

/* eslint-disable react-refresh/only-export-components */

//#region Type definitions

/**
 * Resources that can be created by the user and are returned from the API (and can be shown eg. in the Dashboard
 * "Recently modified" view).
 *
 * NB: Only add that kind of resources here. For other kinds, just create a different, new `type` and constants.
 *
 * NB: Dashboard will show them in the order they are defined here.
 */
export const resourceTypes = [
    "virtual_machine",
    "bare_metal",
    "bucket",
    "ip_address",
    "vpc",
    "load_balancer",
    "access_delegation",
    "api_token",
    "access_impersonation",
    "billing_account",
    "managed_service",
] as const;

export type ResourceType = (typeof resourceTypes)[number];
//#endregion

//#region Extra information
export type ResourceTypeOrDefault = ResourceType | "_default";

const ResourceIconClasses: Record<ResourceTypeOrDefault, string> = {
    _default: "jp-icon-appcatalog",
    virtual_machine: "jp-virtual-machine-icon",
    load_balancer: "jp-load-balancer-icon",
    vpc: "jp-network-icon",
    api_token: "jp-swap-icon",
    access_delegation: "jp-user-group-icon",
    access_impersonation: "jp-user-group-icon",
    bucket: "jp-stack-icon",
    ip_address: "jp-network-2-icon",
    billing_account: "jp-card-icon",
    managed_service: "jp-services-icon",
    bare_metal: "jp-host-icon",
};

const ResourceNames: Record<ResourceTypeOrDefault, string> = {
    _default: "Missing",
    virtual_machine: "Virtual Machine",
    load_balancer: "Load Balancer",
    vpc: "VPC Network",
    api_token: "API Token",
    access_delegation: "Access Delegation",
    access_impersonation: "Access Invitation",
    bucket: "S3 Object Storage",
    ip_address: "Floating IP",
    billing_account: "Billing Account",
    managed_service: "Managed Service",
    bare_metal: "Bare Metal",
};

const ResourceParent: Record<ResourceTypeOrDefault, string> = {
    _default: "Missing",
    virtual_machine: "Compute",
    load_balancer: "Network",
    vpc: "Network",
    api_token: "Access",
    access_delegation: "Access",
    access_impersonation: "Access",
    bucket: "Storage",
    ip_address: "Network",
    billing_account: "Billing",
    managed_service: "Services",
    bare_metal: "Compute",
};

const ResourceDescription: Record<ResourceTypeOrDefault, string | undefined> = {
    _default: "Missing",
    virtual_machine:
        "Create a new Virtual Machine or choose something from the App Catalog to get your services running in minutes.",
    load_balancer:
        "High availability load balancer distributes traffic between virtual machines within the same data center location.",
    vpc: "Create a personal Virtual Private Cloud (VPC) and start managing your networks.",
    api_token: "Create an API Token to use our API.",
    access_delegation:
        "Delegate access to your account to enable trusted users to perform actions with your resources.",
    access_impersonation: "",
    bucket: "Create a new storage bucket and start storing your data in a scalable way - Pay-as-you-grow.",
    ip_address: "Allocate a Floating IP and start managing your network.",
    billing_account: undefined,
    managed_service:
        "Fully managed services - we handle the provisioning of underlying infrastructure and the management of the service.",
    bare_metal:
        "Create a dedicated Bare Metal machine and run your application with maximum performance on a single-tenant server.",
};

export function getResourceTypeName(type: ResourceType) {
    return ResourceNames[type] ?? ResourceNames._default;
}

export function getResourceParent(type: ResourceType) {
    return ResourceParent[type] ?? ResourceParent._default;
}

export function getResourceDescription(type: ResourceType) {
    return ResourceDescription[type] ?? ResourceDescription._default;
}

export function getResourceIconClass(type: ResourceType) {
    return ResourceIconClasses[type] ?? ResourceIconClasses._default;
}
//#endregion

export function getResourceLink(item: AnyResource): RouterLinkProps {
    const { $type } = item;
    switch ($type) {
        case "virtual_machine":
            return vmLink(item);
        case "managed_service":
            return serviceLink(item);
        case "bucket":
            return objectStorageLink(item);
        case "load_balancer":
            return loadBalancerLink(item);
        case "vpc":
            return vpcLink(item);
        case "ip_address":
            return ipAddressLink(item);
        case "access_delegation":
            return delegationLink(item);
        case "api_token":
            return tokenLink(item);
        case "billing_account":
            return billingAccountLink(item);
        case "access_impersonation":
            return profileLink;
        case "bare_metal":
            return leaseLink(item);
        default:
            exhaustiveSwitchCheck($type);
    }
}

export const resourceTypeEnabledAtom = atom((get) => (type: ResourceType) => {
    const config = get(configAtom);
    switch (type) {
        case "virtual_machine":
            return config.vmsEnabled;
        case "managed_service":
            return config.servicesEnabled;
        case "bucket":
            return config.storageEnabled;
        case "load_balancer":
            return config.loadBalancerEnabled;
        case "vpc":
            return config.privateNetworksEnabled;
        case "ip_address":
            return config.ipsEnabled;
        case "bare_metal":
            return config.bareMetalEnabled;
        case "access_delegation":
            return true;
        case "api_token":
            return true;
        case "access_impersonation":
            return true;
        case "billing_account":
            return true;
        default:
            exhaustiveSwitchCheck(type);
    }
});

/** Simple utility atom factory to check if any {@link ResourceType} is enabled */
export function anyResourceEnabledAtom(types: ResourceType[]) {
    return atom((get) => {
        const enabled = get(resourceTypeEnabledAtom);
        return types.some((type) => enabled(type));
    });
}
