import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import * as spec from "./common.ts";

extendZodWithOpenApi(z);

//#region Common query and path parameters
export const location = spec.location.openapi({ param: { name: "location", in: "path", ref: "location" } });

export const billing_account_id = spec.billing_account_id
    .optional()
    .openapi({ param: { name: "billing_account_id", in: "query", ref: "billing_account_id" } });

export const id = z.string().openapi({ param: { name: "id", in: "path", ref: "id" } });

export const uuid = spec.uuid.openapi({ param: { name: "uuid", in: "path", ref: "uuid" } });
export const target_uuid = spec.uuid.openapi({ param: { name: "target_uuid", in: "path", ref: "target_uuid" } });
export const rule_uuid = spec.uuid.openapi({ param: { name: "rule_uuid", in: "path", ref: "rule_uuid" } });
//#endregion
