import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useMemo, useRef, useState } from "react";

export type SimpleMutationResult<T> =
    | {
          state: "idle";
          data?: never;
          error?: never;
      }
    | {
          state: "loading";
          data?: never;
          error?: never;
      }
    | {
          state: "hasData";
          data: T;
          error?: never;
      }
    | {
          state: "hasError";
          data?: never;
          error: unknown;
      };

export function useSimpleMutation<TArgs extends unknown[], TResult>(
    fn: (...args: TArgs) => Promise<TResult>,
): { mutate: (...args: TArgs) => void } & SimpleMutationResult<TResult> {
    const [result, setResult] = useState<SimpleMutationResult<TResult>>({ state: "idle" });
    const promise = useRef<Promise<void> | null>(null);
    const fnRef = useRef(fn);
    fnRef.current = fn;

    return useMemo(() => {
        function mutate(...args: TArgs): void {
            const fn = fnRef.current;

            async function mutate() {
                setResult({ state: "loading" });
                try {
                    const data = await fn(...args);
                    setResult({ state: "hasData", data });
                } catch (error) {
                    setResult({ state: "hasError", error });
                }
            }

            if (promise.current) {
                // Wait for the previous promise to finish before starting a new one
                promise.current = promise.current.finally(() => discardPromise(mutate()));
            } else {
                promise.current = mutate();
            }
        }

        return {
            mutate,
            ...result,
        };
    }, [result]);
}
