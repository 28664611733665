const NOT_SET = Symbol.for("lazy/NOT_SET");

/**
 * @__NO_SIDE_EFFECTS__
 */
export function lazy<T>(fn: () => T): () => T {
    let value: T | typeof NOT_SET = NOT_SET;
    return () => {
        if (value === NOT_SET) {
            value = fn();
        }
        return value;
    };
}
