export function PaymentReturn() {
    return (
        <div className="size-full flex items-center justify-center text-center">
            <div>
                Payment authorization check finished.
                <br />
                <br />
                You can close this page.
            </div>
        </div>
    );
}
