// https://stackoverflow.com/a/2117523/2800218
// LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
function randomUUID_() {
    const random = crypto.getRandomValues(new Uint8Array(32));
    let i = 0;
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (cs) => {
        const c = Number(cs);
        return (c ^ (random[i++] & (15 >> (c / 4)))).toString(16);
    }) as `${string}-${string}-${string}-${string}-${string}`;
}

export const randomUUID: typeof crypto.randomUUID = crypto.randomUUID ? () => crypto.randomUUID() : randomUUID_;
