import { z, type ZodRawShape } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { successResponse } from "../util.ts";
import { broken_boolean, int } from "./common.ts";

extendZodWithOpenApi(z);

const LocationFields = {
    display_name: z.string(),
    description: z.string().describe("(unused in frontend)"),

    is_default: z.boolean().describe("(unused in frontend)"),
    is_preferred: z.boolean().describe("This is the default location to create resources in"),
    is_published: z.boolean().optional(),
    order_nr: int,

    slug: z.string().describe("Code of the data center. Use this for the API location path parameter."),
    country_code: z.string().describe("ISO 3166-1 alpha-3").length(3).openapi({ example: "sgp" }),

    create_resource_disabled: broken_boolean.optional().describe("New resources cannot be created in this location"),
    mikrotik: broken_boolean.optional(),
} as const satisfies ZodRawShape;

export const Location = z.object(LocationFields).openapi({ ref: "Location" });

export const LocationListResponse = z.array(Location);

export const locationPaths: ZodOpenApiPathsObject = {
    "/config/locations": {
        get: {
            summary: "List locations",
            tags: ["location"],
            responses: {
                ...successResponse(LocationListResponse),
            },
        },
    },
};
