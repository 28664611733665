import type { MainConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import { selectAtom } from "jotai/utils";
import { atom, getDefaultStore, type Atom, type PrimitiveAtom } from "jotai/vanilla";
import { mockConfig } from "./config.mock.ts";
import type { ConfigDefaults, OptionalConfigFields, SiteConfig } from "./config.types.ts";
import { isTestEnvironment } from "./utils/environment.ts";
import { atomWithLocalStorage } from "./utils/jotai/atomStorage.ts";
import { stableAtom } from "./utils/stableAtom.ts";

/**
 * Default configuration. Will be used if no fetched or mocked configuration is available.
 *
 * Must set defaults for {@link OptionalConfigFields} – for backwards compatibility.
 */
const defaultConfig = {
    loginSubtitle: "",
    loginTitle: "Log in",

    vmsEnabled: true,
    ipsEnabled: true,
    bareMetalEnabled: false,

    chatEnabled: !import.meta.env.DEV,

    locations: [],
} satisfies ConfigDefaults; //#endregion

//#region Atom & utility functions

/**
 * Atom that contains an atom that will have the fetched configuration from the backend.
 *
 * This will be set from the application Root. {@link configAtom} itself does not initiate fetch, to prevent cyclic/deep dependencies which interfere with hot reload.
 */
export const injectedConfigAtom = stableAtom<Atom<MainConfig | undefined>>("injectedConfigAtom", () => atom(undefined));

/** Atom that contains the loaded configuration, or `undefined` if not loaded yet. */
export const optionalConfigAtom = atom((get): SiteConfig | undefined => {
    const injectedConfig =
        get(get(injectedConfigAtom)) ??
        (isTestEnvironment
            ? // Simple stories and Vite tests will not have the injected config set up, so fall back to the default mock config
              mockConfig
            : undefined);

    if (!injectedConfig) {
        return undefined;
    }

    return { ...defaultConfig, ...injectedConfig, ...get(configOverridesAtom) };
});

/** Atom that contains the site configuration. Throws if not loaded yet. */
export const configAtom = atom((get): SiteConfig => {
    const optionalConfig = get(optionalConfigAtom);
    if (!optionalConfig) {
        throw new Error("Configuration not loaded yet, it must be manually fetched before the first use");
    }
    return optionalConfig;
});

export function useConfig() {
    return useAtomValue(configAtom);
}

/** WARNING: Do not use this function unless necessary. The config values will not be overridable in tests and Storybook. */
export function getGlobalConfig_UNTESTABLE() {
    return getDefaultStore().get(configAtom);
}

//#endregion

//#region Convenience atoms (to optimize re-renders on mock changes, only depend on the part of the config that is used)

export const siteCurrencyAtom = selectAtom(configAtom, (config) => config.siteCurrency);
export const siteLocaleAtom = selectAtom(configAtom, (config) => config.siteLocale);
export const siteThemeAtom = selectAtom(configAtom, (config) => config.siteTheme);
export const siteKubernetesAtom = selectAtom(configAtom, (config) => config.kubernetesEnabled);

/** (Only returns the chat config if it is enabled) */
export const chatConfigAtom = selectAtom(configAtom, (config) => (config.chatEnabled ? config.chatConfig : undefined));

//#endregion

//#region Configuration override support for debugging and mocking

export type SiteConfigOverrides = Partial<SiteConfig>;

export const NO_OVERRIDES: SiteConfigOverrides = {};

/** NB: do not access directly, use `updateConfigOverrideAtom` instead. */
export const configOverridesAtom: PrimitiveAtom<SiteConfigOverrides> =
    isTestEnvironment || import.meta.env.PROD
        ? // NB: Do not persist to local storage in tests, or production
          atom(NO_OVERRIDES)
        : atomWithLocalStorage("configOverride", NO_OVERRIDES);

//#endregion
