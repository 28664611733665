/** Props common to all FormFields */
export interface StandardFieldProps {
    isRequired?: boolean;
    isDisabled?: boolean;
}

/** Shared element attributes for form field rows (eg. FormField, TextField, Radio) */
export function standardFieldAttributes({ isRequired, isDisabled }: StandardFieldProps) {
    return {
        "data-required": isRequired ? true : undefined,
        "data-disabled": isDisabled ? true : undefined,
    };
}
