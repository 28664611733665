import "./styles/styles";

import ReactDOM from "react-dom/client";
import { initDev } from "./dev/initDev";
import { beginMockDbInit } from "./mock-api/msw/mswInit";
import Root from "./root";

function main() {
    initDev();

    ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);

    beginMockDbInit({ persistentMockDbEnabled: true, mockDbOptions: {} });
}

main();
