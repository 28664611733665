type InferIdType<T> = T extends { id: infer U } ? U : never;

/** Simple utility to find an item in an array by its `id` field. */
export function findById<TItem extends { id: unknown }, TKey extends InferIdType<TItem>>(
    collection: Iterable<TItem>,
    id: TKey,
    defaultValue?: TItem,
) {
    for (const item of collection) {
        if (item.id === id) {
            return item;
        }
    }

    if (defaultValue !== undefined) {
        return defaultValue;
    }

    throw new Error(`ID ${id} was not found in collection`);
}
