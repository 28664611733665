import { link, type RouterLinkProps } from "../../components/Action.tsx";
import type { ComputeImageType } from "../compute/os/os.ts";
import type { ResourceType } from "./resourceTypes.tsx";

export const ResourceCreateLinks = {
    /* TODO: vm & app should have proper type in url */
    virtual_machine: link({ to: "/compute/create" }),
    managed_service: link({ to: "/services/create" }),
    load_balancer: link({ to: "/network/create/$type", params: { type: "load_balancer" satisfies ResourceType } }),
    vpc: link({ to: "/network/create/$type", params: { type: "vpc" satisfies ResourceType } }),
    ip_address: link({ to: "/network/create/$type", params: { type: "ip_address" satisfies ResourceType } }),
    api_token: link({ to: "/access/create/$type", params: { type: "api_token" satisfies ResourceType } }),
    access_delegation: link({
        to: "/access/create/$type",
        params: { type: "access_delegation" satisfies ResourceType },
    }),
    bare_metal: link({ to: "/metal/create" }),

    /** Access impersonations can not be created, only received */
    access_impersonation: undefined,
    bucket: link({ to: "/storage/create" }),
    billing_account: link({ to: "/billing/create" }),
} as const satisfies Record<ResourceType, RouterLinkProps | undefined>;

export const profileLink = link({ to: "/profile" });

/** Default type for Access Create */
export const accessCreateLink = ResourceCreateLinks.api_token;
/** Default type for Network Create */
export const networkCreateLink = ResourceCreateLinks.ip_address;

export function getResourceCreateLink(type: ResourceType) {
    return ResourceCreateLinks[type];
}

export function getAppCreateLink(osName: string, osVersion: string) {
    return link({
        to: "/compute/create",
        search: {
            typeId: "app_catalog" satisfies ComputeImageType,
            osName,
            osVersion,
        },
    });
}

export function getServiceCreateLink(osName: string, osVersion: string) {
    return link({
        to: "/services/create",
        search: {
            osName,
            osVersion,
        },
    });
}
