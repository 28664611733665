import { TinyColor } from "@ctrl/tinycolor";
import { objFromEntries } from "@warrenio/utils/collections/objs";
import type { LiteralUnion } from "type-fest";
import { getVarFromBuilderContext } from "../themeBuilder";
import type { BaseColors } from "../types";
import { colorString } from "./tinyColorUtils";

export type ColorLikeString = LiteralUnion<BaseColors, string>;

export type ColorLike = TinyColor | ColorLikeString;

//#region Color Utilities

/** Create a color from a CSS color string or a {@link baseColors} variable name. */
export function tc(color: ColorLike) {
    if (color instanceof TinyColor) {
        return color;
    }

    const result = new TinyColor(getVarFromBuilderContext(color) ?? color);
    if (!result.isValid) {
        throw new Error(`Invalid color: ${color}`);
    }
    return result;
}

/** Change the alpha on a color. */
export function alpha(color: ColorLike, alpha: number) {
    return colorString(tc(color).setAlpha(alpha));
}

/** Mix two colors. Functions similarly to alpha.
 *
 * With {@link intensity} = 0, it will return the background color.
 *
 * With {@link intensity} = 1, it will return the original color.
 */
export function mix(color: ColorLike, intensity: number, backgroundColor: ColorLike) {
    const bg = tc(backgroundColor);
    const newColor = bg.mix(tc(color), intensity * 100);
    return colorString(newColor);
}

/** Mix the color with `color-white`
 *
 * {@link newIntensity} = 1 will return the original color.
 */
export function whiten(color: ColorLike, newIntensity: number) {
    return mix(color, newIntensity, "color-white");
}

/** Mix the color with `color-black` */
export function darken(color: ColorLike, newIntensity: number) {
    return mix(color, newIntensity, "color-black");
}

/** Create a very light hover color. Will have enough contrast with gray text. */
export function hoverMix(color: ColorLike, intensity = 0.08, backgroundColor?: ColorLike) {
    const c = tc(color);
    const bg = tc(backgroundColor ?? "color-white");
    const isDarkBg = bg.getLuminance() < 0.4;
    // Dark background requires boost for hover to be visible
    const boost = 0.15;
    intensity = isDarkBg ? Math.min(intensity > 0.5 + boost ? intensity - boost : intensity + boost, 1) : intensity;

    return mix(c, intensity, bg);
}

//#endregion

//#region Automatic colors

export function makeGreys() {
    let greyStart: TinyColor | undefined;
    let greyEnd: string | undefined;

    // Balances between white and black for each grey step
    const steps = [0.06, 0.1, 0.2, 0.35, 1];
    return objFromEntries([1, 2, 3, 4, 5] as const, (i) => [
        `color-grey-${i}` as const,
        () => {
            greyStart ??= tc("color-white");
            greyEnd ??= mix("color-black", 0.5, "color-white");
            return mix(greyEnd, steps[i - 1], greyStart);
        },
    ]);
}

//#endregion
