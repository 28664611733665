import * as spec from "@warrenio/api-spec/main-openapi-spec";
import { deepStrict } from "@warrenio/api-spec/strict";
import { z } from "zod";
import type {
    ZodOpenApiOperationObject,
    ZodOpenApiPathItemObject,
    ZodOpenApiResponseObject,
    ZodOpenApiResponsesObject,
} from "zod-openapi";
import { showError, showWarn } from "../../dev/errorStream.ts";
import type { ApiClient } from "./apiClient.ts";
import { ApiZodValidationError } from "./ApiError.ts";

/** Automatically check that the response data matches the Zod API spec's declared schema. */
export function registerSchemaCheckMiddleware(client: ApiClient) {
    if (!import.meta.env.DEV) return;

    client.use({
        async onResponse({ request: { method }, schemaPath, response }) {
            const schema = getSchemaForRequest(schemaPath, method, response);
            if (schema) {
                let data: unknown;

                try {
                    // Clone the response so we can read it twice
                    data = await response.clone().json();
                } catch (e) {
                    console.error("Failed to parse JSON response for schema check", e);
                    return response;
                }

                validateSchema(response, schema, data);
            }

            return response;
        },
    });
}

/** Get the Zod schema for an OpenAPI endpoint. */
function getSchemaForRequest(schemaPath: string, method: string, response: Response): z.ZodTypeAny | undefined {
    const pathInfo: ZodOpenApiPathItemObject | undefined = spec.paths[schemaPath];
    const methodInfo = pathInfo?.[method.toLowerCase() as keyof ZodOpenApiPathItemObject] as
        | ZodOpenApiOperationObject
        | undefined;
    const responseInfo = methodInfo?.responses[String(response.status) as keyof ZodOpenApiResponsesObject] as
        | ZodOpenApiResponseObject
        | undefined;

    const responseContentType = response.headers.get("content-type");
    if (responseContentType && !responseContentType.includes("application/json")) {
        console.warn("Skipping schema check for non-JSON response: %s @ %s", responseContentType, response.url);
        return;
    }
    const contentType = "application/json";

    const schema = responseInfo?.content?.[contentType]?.schema;

    // console.debug({ pathInfo, methodInfo, responseInfo, schema });

    if (schema == null) {
        showWarn("No Zod schema found for %s[%s][%s]", schemaPath, method, response.status);
    } else if (!(schema instanceof z.ZodType)) {
        console.warn("Invalid Zod type for %s[%s][%s]: %o", schemaPath, method, response.status, schema);
    } else {
        return schema;
    }
}

export function validateSchema<T>(response: Response, schema: z.ZodTypeAny, data: T): T {
    // console.debug("Validating API response from %s: %o ~~~ %o", response.url, data, schema);
    schema = deepStrict(schema);
    try {
        schema.parse(data);
    } catch (e) {
        const finalErr = e instanceof z.ZodError ? new ApiZodValidationError(response.url, e) : e;

        showError("Failed to validate API response from %s:\n%o\nSchema: %o", response.url, data, finalErr);
    }

    return data;
}
