import { link } from "../../components/Action.tsx";
import type { BillingAccountWithType } from "./billingAccountQuery.ts";

export function billingAccountLink({ id }: Pick<BillingAccountWithType, "id">) {
    return link({ to: "/billing/account/$id", params: { id: String(id) } });
}

export function billingAccountEditLink({ id }: Pick<BillingAccountWithType, "id">) {
    return link({ to: "/billing/account/$id/edit", params: { id: String(id) } });
}
