import { useAtom, useAtomValue } from "jotai/react";
import { WButton, type WButtonProps } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { WTooltip } from "../../../components/WTooltip.tsx";
import { configOverridesAtom } from "../../../config.ts";
import { debugBarVisibleAtom } from "./DebugMenu.store.ts";

export function BugButton({ isActive, ...props }: { isActive: boolean } & WButtonProps) {
    return (
        <WButton
            color="primary"
            variant={isActive ? undefined : "ghost"}
            size="bar"
            icon="i-lucide:bug"
            ariaLabel="Debug menu"
            {...props}
        />
    );
}

function ConfigOverrideWarning() {
    const configOverrides = useAtomValue(configOverridesAtom);
    const overrideKeys = Object.keys(configOverrides);
    if (!overrideKeys.length) {
        return null;
    }

    return (
        <WTooltip
            text={
                <>
                    Config overrides active:
                    <br />
                    {overrideKeys.join(", ")}
                </>
            }
        >
            <MaskIcon className="i-lucide:zap mx-1 size-0.875rem text-warning" />
        </WTooltip>
    );
}

/** In a separate file to split dependency graph */
export function DebugButton() {
    const [visible, setVisible] = useAtom(debugBarVisibleAtom);
    return (
        <>
            <ConfigOverrideWarning />
            <BugButton action={() => setVisible((v) => !v)} isActive={visible} />
        </>
    );
}

export default DebugButton;
