import type { ReactNode } from "react";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { mcn, type BaseProps } from "../../utils/baseProps.ts";

export function ErrorBox({ children, ...props }: { children: ReactNode } & BaseProps) {
    return (
        <div {...mcn("size-full flex flex-col text-center items-center justify-center p-4", props)}>
            <MaskIcon className="jp-cactus-icon size-5rem color-grey-4 mb-4" />
            {children}
        </div>
    );
}
