// NB: we do not import any actual functions here so we can load this module without loading the rest of the SDK
import type auth0 from "auth0-js";

import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { atom } from "jotai/vanilla";
import { addDevVars } from "../../../dev/initDev.tsx";
import { auth0DomainCheckAtom, auth0OptionsAtom } from "./auth0Config.ts";

const importAuth0 = makeOnce(() => import("auth0-js"));

export function preloadAuth0() {
    discardPromise(importAuth0());
}

export const webAuthAtom = atom<Promise<auth0.WebAuth>>(async (get) => {
    // Check domain
    get(auth0DomainCheckAtom).warnIfInvalid();

    // Construct
    const auth0 = await importAuth0();
    const webAuth = new auth0.WebAuth(get(auth0OptionsAtom));

    // Debug
    addDevVars({ webAuth });

    return webAuth;
});
