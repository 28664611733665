import { z } from "zod";
import {
    extendZodWithOpenApi,
    type oas31,
    type ZodOpenApiMediaTypeObject,
    type ZodOpenApiOperationObject,
    type ZodOpenApiPathsObject,
    type ZodOpenApiRequestBodyObject,
    type ZodOpenApiResponsesObject,
} from "zod-openapi";

extendZodWithOpenApi(z);

/** Description marker for private types / fields */
export const privateApi = "[private]";

/** A template string literal that de-indents. */
export function ind(template: TemplateStringsArray, ...subs: any[]): string {
    const result = template[0].replace(/\n[ \t]*/g, "\n").replace(/^\n/, "");
    return result + subs.map((s, i) => s + template[i + 1]).join("");
}

/** Remove OpenAPI `ref` so eg. the description or example can be overridden. */
export function unref<T extends z.ZodTypeAny>(type: T) {
    return type.openapi({ ref: undefined });
}

/** Convert a string to a boolean. For use in form bodies (similarly to eg. `zfd.numeric()`) */
export function formBoolean<T extends z.ZodTypeAny>(schema: T) {
    return z.preprocess((arg) => {
        if (arg === "true" || arg === "1") {
            return true;
        }
        if (arg === "false" || arg === "0" || arg === "") {
            return false;
        }
        return arg;
    }, schema);
}

//#region Standard response schemas

export function successResponse<T extends z.Schema>(
    schema: T,
    description = "Successful response",
): ZodOpenApiResponsesObject {
    return {
        "200": {
            description,
            content: { "application/json": { schema } },
        },
    };
}

export const ErrorSchema = z
    .object({
        errors: z.object({ Error: z.string().describe("Error message") }),
    })
    .describe("Common error type")
    .openapi({ ref: "ApiErrorResponse" });

export function errorResponse(example?: string): ZodOpenApiResponsesObject {
    return customErrorResponse(ErrorSchema, example ? { errors: { Error: example } } : undefined);
}

export function customErrorResponse<T extends z.Schema>(
    schema: T,
    example?: unknown,
    description = "Error response",
): ZodOpenApiResponsesObject {
    const extra: ZodOpenApiMediaTypeObject | undefined = example ? { example } : undefined;
    return {
        "500": {
            description,
            content: {
                "application/json": { schema, ...extra },
            },
        },
    };
}

//#endregion

//#region Request body schemas

/** Utility to create a standard OpenAPI form body from a Zod schema */
export function formBody<T>(schema: z.Schema<T>, extra?: Partial<oas31.MediaTypeObject>): ZodOpenApiRequestBodyObject {
    return {
        required: true,
        content: {
            "application/x-www-form-urlencoded": { schema, ...extra },
        },
    };
}

export function jsonBody<T>(schema: z.Schema<T>, extra?: Partial<oas31.MediaTypeObject>): ZodOpenApiRequestBodyObject {
    return {
        required: true,
        content: {
            "application/json": { schema, ...extra },
        },
    };
}

//#endregion

//#region Transforming OpenAPI path specifications

export function walkPaths(fn: (paths: ZodOpenApiOperationObject, method: string, path: string) => void) {
    return (paths: ZodOpenApiPathsObject) => {
        for (const [path, methods] of Object.entries(paths)) {
            for (const [method, operation] of Object.entries(methods as Record<string, ZodOpenApiOperationObject>)) {
                fn(operation, method, path);
            }
        }
        return paths;
    };
}

export function transformPaths(
    fn: (paths: ZodOpenApiOperationObject, method: string, path: string) => ZodOpenApiOperationObject | undefined,
) {
    return (paths: ZodOpenApiPathsObject) => {
        for (const [path, methods_] of Object.entries(paths)) {
            const methods = methods_ as Record<string, ZodOpenApiOperationObject>;
            for (const [method, operation] of Object.entries(methods)) {
                const newOperation = fn(operation, method, path);
                if (newOperation !== undefined) {
                    methods[method] = newOperation;
                } else {
                    delete methods[method];
                }
            }

            if (Object.keys(methods).length === 0) {
                delete paths[path];
            }
        }
    };
}

export function tagPaths(...tags: string[]) {
    return walkPaths((operation) => {
        operation.tags ??= tags;
    });
}

//#endregion
