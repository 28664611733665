import { colorSchemeAttribute } from "./shared.ts";
import type { CssVars, Theme } from "./types.ts";

function cssVarsToCssString(vars: CssVars): string {
    return Object.entries(vars)
        .filter((v): v is [string, string] => v[1] != null)
        .map(([k, v]) => `  --${k}: ${v};`)
        .join("\n");
}

function selectorVars(selector: string, vars: CssVars) {
    return `${selector} {\n${cssVarsToCssString(vars)}\n}\n`;
}

const darkModeSelector = `html[${colorSchemeAttribute}='dark'], html.dark`;

export function themeToCss({ vars, darkVars }: Theme) {
    let css = selectorVars(":root", vars);
    if (darkVars) {
        const optimizedDarkVars = Object.fromEntries(
            Object.entries(darkVars).filter(([k, v]) => v !== (vars as Record<string, unknown>)[k]),
        );

        css += "\n/*** Dark theme ***/\n";
        css += selectorVars(darkModeSelector, optimizedDarkVars);
    }
    return css;
}
