// NB: We do not import any actual functions here, so we can load this module without loading the rest of the SDK
import type { AuthOptions } from "auth0-js";
import { selectAtom } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { configAtom } from "../../../config.ts";
import { path_auth0Callback } from "../../../paths.ts";
import { getFullUrl } from "../../main/urls.ts";

const auth0ConfigAtom = selectAtom(configAtom, (c) => c.auth0);

export const auth0OptionsAtom = atom((get): AuthOptions => {
    return {
        ...get(auth0ConfigAtom),
        redirectUri: getFullUrl(path_auth0Callback),
        responseType: "token id_token",
        responseMode: "fragment",
        scope: "openid profile email",
    };
});

export const auth0Realm = "Username-Password-Authentication";

function inSameCookieDomain(current: string, configured: string) {
    const targetParts = current.split(".");
    const configuredParts = configured.split(".");

    const offset = -configuredParts.length + 1;

    const tld = configuredParts.slice(offset).join(".");
    const currentTld = targetParts.slice(offset).join(".");
    console.debug("Cookie domain check: %o", { currentTld, tld });
    return currentTld === tld;
}

interface Auth0DomainCheck {
    /** Warn when current origin domain is not suitable for Auth0 callbacks */
    warnIfInvalid(): void;
    isValid: boolean;
}

export const auth0DomainCheckAtom = atom<Auth0DomainCheck>((get) => {
    const effectiveDomain = document.location.hostname;
    const auth0Options = get(auth0OptionsAtom);

    const isValid = inSameCookieDomain(effectiveDomain, auth0Options.domain);

    function warnIfInvalid() {
        if (!isValid) {
            console.warn(
                "Auth0 cross-origin logins will be broken, please use a domain related to %o instead of %o",
                auth0Options.domain,
                effectiveDomain,
            );
        }
    }

    return {
        isValid,
        warnIfInvalid,
    };
});
