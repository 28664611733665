/** Initialize utilities useful in development mode (eg. for the browser console) */
export function initDev() {
    if (!import.meta.env.DEV) return;

    addDevVars({ import: (path: string) => import(/* @vite-ignore */ path) });

    document.title += " (dev)";
}

/**
 * Add variables that can be accessed in the browser console as `D.var`
 * @__NO_SIDE_EFFECTS__
 */
export function addDevVars(vars: Record<string, unknown>) {
    if (!import.meta.env.DEV) return;

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        Object.assign(((window as any).D ??= {}), vars);
    } catch (e) {
        console.error(e);
    }
}
